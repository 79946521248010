<!--component html goes here -->
<div class="col-sm-12 compswitch ">
  <div class="row">
    <div class="col-sm-3 col-xs-1"></div>
    <div class="col-sm-2 col-xs-3 round-icon">
      <div>
     
        <p class="selectprior optionhigh2 casetito" [matTooltip]="data.Title" matTooltipPosition="after"> {{truncateString(data.Title)}} </p> 
        <div class="flex-container">
          <!-- <div class="selectpriorGrp optionhigh2 flexo" *ngFor="let item of groupName"> {{item}} </div>  -->
          <b class="selGroup" *ngIf="product !== 'corporate'">Selected Groups:</b>
          <b class="selGroup" *ngIf="product === 'corporate'">Selected Departments:</b>
          <div class="divShow" *ngFor="let item of (showAllItems ? groupName : groupName.slice(0, 2)); let i = index">
            {{ item }}
            <span *ngIf="groupName.length == 1"> </span>
            <span *ngIf="groupName.length > 1 && i < (showAllItems ? groupName.length : 2) - 1">, </span>
          </div>
          <div *ngIf="groupName.length > 2" class="view-more-less" (click)="toggleView()">
            <a href="javascript:void(0)">{{ showAllItems ? 'View Less' : 'View More' }}</a>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="col-12 Individual createGroup">
    <div class="row">
      <div class="col-6 matterpadding">
        <h5 class="float-left grpFont">Selected Document(s)</h5>
        <div class="selectedone">
          <div class="form-group group-custom-align" *ngFor="let document of selectedDocuments">
            <div class="input-group multicheck court" id="selectedgroup">
              <div class="form-control textbox textAl" [innerHtml]="document.name"></div>
              <i class="fa fa-regular fa-circle-xmark calimg calitem input-group-append sgroup" aria-hidden="true"
                (click)="removeDocument(document)"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 matterpadding">
        <h5 class="float-left grpFont">Attach Document(s)</h5>
        <h5 class="float-right lop" *ngIf="isSelectAllVisible">
          <label class="callselect checkAll">Select All</label>&nbsp;
          <input type="checkbox" value="" 
          [checked]="filteredData?.length > 0 && selectedDocuments.length === filteredData.length" 
          id="selectAll" (change)="selectAll($event)" #selectAllCheckbox>
        </h5>
        
        <div class="switch relationship-tabs cswitch float-left">
          <span class="relSubTab individuals" (click)="currentTab='existingdoc';">
            <a href="javascript: void(0)" [ngClass]="{'active' : currentTab=='existingdoc'}"
              class="optionone compone relation-sub-tabs">Add Existing</a>
          </span>

           <!--<span class="relSubTab business" (click)="currentTab='uploadnew';">
            <a href="javascript: void(0)" [ngClass]="{'active' : currentTab=='uploadnew'}"
              class="optiontwo relation-sub-tabs compotwoo comptwo">Upload New</a>
          </span>--> 
        </div>
        <div class="matterpadding" *ngIf="currentTab=='existingdoc'">
          <!--<div class="col-3">
            <h5>Attach Document(s)</h5>
          </div>-->
          <!-- <div class="selOn" *ngIf="isSelectAllVisible">
            <label class="callselect checkAll">Select All</label>&nbsp;
            <input type="checkbox" value="" id="selectAll" (change)="selectAll($event)" #selectAllCheckbox>
          </div> -->
          <!-- <div class="col-3">
            <div class="form-group" id="selectbx">
              <div class="input-group court" id="search">
                <div class="multicheck form-control textbox" *ngIf="isSelectAllVisible">
                  <label class="callselect selAl">Select All</label>
                  <input class="form-check-inpu calimg calitem input-group-append checkboxitem" type="checkbox" value=""
                    id="selectAll" (change)="selectAll($event)" #selectAllCheckbox>
                </div>
              </div>
            </div>
          </div> -->
          <div class="form-group ">
            <div class="input-group group-custom-align court" id="search">
              <input type="text" class="form-control textbox searchtextcr" placeholder="Search Document" [(ngModel)]="searchText" (keyup)="keyup()" />
              <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
            </div>
          </div>
          <div class="form-group " id="selectbx ">
            <div class="input-group group-custom-align court matterpadding" id="search"
              *ngFor="let document of documentsList | filter:searchText">
              <div class="multicheck form-control textbox">
                <div class="callselect textAl" [innerHtml]="document.name"></div>
                <input class="form-check-inpu calimg calitem input-group-app end checkboxitem" type="checkbox" id="flexCheckDefault"
                  (change)="selectDocument(document)">
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="currentTab=='uploadnew'">
      <div class="row">
        <div class="col-6 "></div>
        <div class="col-6 ">
          <div class="col-3">
            <h5>Attach Document(s)</h5>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 "></div>
        <div class="col-6 ">
          <div class="form-group">
            <div class="input-group court" id="search">
              <input multiple #file type="file" id="file" style="display:none;" class="myclass"
                class="form-control textbox" (change)="getFileDetails($event.target)" />
              <input type="text" id="file" class="myclass" class="form-control textbox" />
              <button id="btnadd" for="file" class="input-group-append appendadd" (click)="file.click()">Browse</button>
            </div>
            <h6>upload one or more documents </h6>
          </div>
        </div>
        <div class="row">
          <div class="col-6 "></div>
          <div class="col-6 width-align-upload " *ngIf="uploadDocs.length>0">
            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
              <input type="radio" class="btn-check" name="btnradio1" id="btnradio4" autocomplete="off">
              <label class="btn btn-outline-primary" for="btnradio4" (click)="filterDoc('addTags')">Add Tags</label>
              <input type="radio" class="btn-check" name="btnradio1" id="btnradio3" autocomplete="off">
              <label class="btn btn-outline-primary" for="btnradio3" (click)="filterDoc('editMeta')">Edit
                Metadeta</label>
            </div>
            <div></div>
            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
              <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
              <label class="btn btn-outline-primary" for="btnradio1" (click)="docEnable('enable')">Enable
                Download</label>

              <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
              <label class="btn btn-outline-primary" for="btnradio2" (click)="docEnable('disable')">Disable
                Download</label>
            </div>
            <div *ngIf="!editMetaFlag" class="selectAll float-right"> Select All <input class="inputEl" #inputEl
                type="checkbox" (change)="checkAllItem(inputEl.checked)"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 "></div>
          <div class="col-6 ">
            <div class="cod" *ngFor="let item of uploadDocs;let i = index">

              <div #itemlist class="documents-list ng-item" [ngClass]="{'active': selectedIdx == i}"> {{item.name}}
                <div class="icon-list">
                  <div class="icons" *ngIf="editMetaFlag && editMetaData"> <i (click)="editDocument(item,i)"
                      class="fa fa-edit"></i></div>
                  <div class="icons" *ngIf="!editMetaFlag"><input type="checkbox" [checked]="item.checked"
                      (click)="checkedItem(item)"></div>
                  <div class="icons" [ngClass]="{'disable':!downloadDisabled }"><i class="fa fa-download"></i></div>
                  <div class="icons"><i class="fa  fa-times-circle" style="color:red"
                      (click)="removeNewDocument(item,i)"></i>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="row metadata">
          <div class="col-6 "></div>
          <div *ngIf="!editMetaFlag" class="col-6 ">
            <div class=" name-set">
              <div class="tags">
                <div class="tags-width float-right"><button class="btn btn-primary" (click)="addvalue()">ADD</button>
                </div>
              </div>

              <div *ngFor="let value of values; let i = index">
                <div class="col-12">
                  <div class="row">
                    <div class="col-5">
                      <div class="name-set">
                        <label for="title" class="fontbold tab-margin">Tag Type </label>
                        <input type="text" [(ngModel)]="value.tagtype" #name="ngModel" name="tags{{ i }}"
                          class="form-control textbox" id="caseType">
                      </div>
                    </div>
                    <div class="col-5">
                      <div class="name-set">
                        <label for="title" class="fontbold tab-margin">Tags </label>
                        <input type="text" [(ngModel)]="value.tag" #name="ngModel" name="tags{{ i }}"
                          class="form-control textbox" id="caseType">
                      </div>
                    </div>
                    <div class="col-1">
                      <label for="title" class="fontbold tab-margin"> </label>
                      <div (click)="removevalue(i)"><i class="fa  fa-times-circle" aria-hidden="true"></i></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btnbox">
              <button type="button" class="btn btn-default btncancel editcancel" (click)="onResetTags()">Cancel</button>
              <button type="submit" class="btn btn-default btnsave tagsave" (click)="submit()">Save</button>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="editDoc">
          <div class="col-6 "></div>
          <div class="col-6 ">
            <hr class="new3">
            <form [formGroup]="documentDetail" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label for="documentName" class="fontbold">Document Name <span class="mandate">*</span>
                </label>
                <input type="text" class="form-control textbox"  id="documentName"
                  name="documentName" formControlName="name"
                  [ngClass]="{'is-invalid':submitted && f.name.errors}">
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                  <div *ngIf="f.name.errors.required">Document Name is required</div>
                </div>
              </div>
              <div class="form-group">
                <label for="title" class="fontbold"> Expiration Date</label>
                <mat-form-field appearance="fill" class="form-group">
                  <input matInput [matDatepicker]="disableTyping" formControlName="date_of_filling"
                    name="date_of_filling" class="form-control " readonly>
                  <mat-datepicker-toggle matSuffix [for]="disableTyping">
                  </mat-datepicker-toggle>
                  <mat-datepicker #disableTyping></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-group">
                <label for="filling date" class="fontbold">Description <span class="mandate">*</span></label>
                <textarea rows="4"  cols="50" class="form-control textbox"
                  formControlName="description" name="description"
                  [ngClass]="{'is-invalid':submitted && f.description.errors}" maxlength="300"></textarea>
                <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                  <div *ngIf="f.description.errors.required">description is required</div>
                </div>
                <div class="count-div">
                  <div class="count-text" *ngIf="f.description">{{f.description.value.length}}/300</div>
                </div>
              </div>
              <div class="btnbox editbtn">
                <button type="button" class="btn btn-default btncancel editcancel" (click)="onReset()">Cancel</button>
                <button type="submit" class="btn btn-default btnsave savecls tagsave" [ngClass]="{'disabled' : documentDetail.invalid}">Save</button>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="btnbox">
      <button type="reset" class="btn btn-default btncancel btnrightitem" (click)="OnCancel()">Cancel</button>
      <button type="submit" class="btn btn-primary btnsave savecls" (click)="saveDocuments()">Submit</button>
  
    </div>
  </div>
</div>