<div class="container main test width-alignment">
    <!-- <div *ngIf="successMsg">
        {{successMsg}} -->
    <!-- </div> -->
    <div class="col-12 Individual createGroup">
        <div class="row">
            <div class="col-sm-4 col-xs-1">
                <label for="select" class="lable-size update" style="margin:10px" *ngIf="product != 'corporate' && showMemlist">Assign to groups</label>
                <label for="select" class="lable-size update" style="margin:10px" *ngIf="product == 'corporate' && showMemlist">Assign to departments</label>
            </div>
            <div class="col-sm-2 col-xs-3 round-icon">
              <div class="steprow">
                <a href="javascript: void(0)"
                   class="stepone"
                   [ngClass]="{'green': showMemlist }">
                </a>
              </div>
              <div><p class="ptext">Team Member Info</p> </div>
            </div>
            <div class="col-sm-2 col-xs-3 round-icon">
              <div class="steprow">
                <a href="javascript: void(0)"
                   class="stepone"
                   [ngClass]="{'green': showMemlist }">
                </a>
              </div>
              <div>
                <p class="ptext" *ngIf="product != 'corporate'">Assign to Groups</p> 
                <p class="ptext" *ngIf="product == 'corporate'">Assign to Departments</p> 
             </div>
            </div>
            <div class="col-sm-3 col-xs-3"> </div>
        </div>
        <!-- <h5>&nbsp;</h5> -->
        <!-- <div class="subalert" *ngIf="error">{{error}}</div> -->
        <div class="subalert" *ngIf="disablePage">Your present subscription does not allow creation of more users</div>
        <div class="blur-overlay">
        <form [formGroup]="createMemberForm" (ngSubmit)="onSubmit()">
            <div class="row" *ngIf='showForm' [ngClass]="{'blurred': disablePage}">
                <div class="col-6">
                    <div class="form-group errorMsg nameSet ">
                        <label for="title" class="lable-size">Name <span class="mandate">*</span></label>
                        <input type="text" (input)="restricttextSpace($event)"
                               formControlName="name"
                               class=" text-size form-control member-name-text"
                               [ngClass]="{ 'is-invalid': submitted && f.name.errors }" id="caseTitle"
                               name="name">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback ">
                            <div *ngIf="f.name.errors.required">
                                Name is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group errorMsg nameSet">
                        <label for="comment" class="lable-size">Designation <span class="mandate">*</span></label>
                        <input type="text" (input)="restricttextSpace($event)"
                               formControlName="designation"
                               class=" text-size form-control member-name-text"
                               [ngClass]="{ 'is-invalid': submitted && f.designation.errors }"
                               id="caseTitle"
                               name="designation">
                        <div *ngIf="submitted && f.designation.errors" class="invalid-feedback">
                            <div *ngIf="f.designation.errors.required">
                                Designation is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group errorMsg nameSet">
                        <div class="row">
                            <div class="col-6 ">
                                <label for="select" class="lable-size">Default Currency <span class="mandate">*</span></label>
                                <select formControlName="currency" style="background-color: #EEEEEE;cursor:pointer"
                                        class="form-select member-name-text nameSet"
                                        aria-label="Default select example"
                                        [ngClass]="{ 'is-invalid':submitted && f.currency.errors }"
                                        name="currency">
                                    <option *ngFor="let c of currencyList">{{c}}</option>
                                </select>
                                <div *ngIf="submitted && f.currency.errors" class="invalid-feedback">
                                    <div *ngIf="f.currency.errors.required">
                                        Currency is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 ">
                                <label for="title" class="lable-size">Default Rate <span class="mandate">*</span></label>
                                <input type="text" (input)="restricttextSpace($event);restrictToNumbers($event)"
                                       formControlName="defaultRate" [ngClass]="{ 'is-invalid': submitted && f.defaultRate.errors }" class="nameSet text-size form-control member-name-text drate" id="caseTitle" name="defaultRate">
                                <div *ngIf="submitted && f.defaultRate.errors" class="invalid-feedback ">
                                    <div *ngIf="f.defaultRate.errors.required">
                                        Hourly rate is required       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class=" form-group errorMsg  nameSet">
                        <label for="exampleInputEmail1" class="form-label lable-size">Email <span class="mandate">*</span></label>
                        <input type="email" (input)="restricttextSpace($event);resetEmailConf()"
                               formControlName="email"
                               class="form-control member-name-text"
                               id="exampleInputEmail1"
                               aria-describedby="emailHelp"
                               (keyup)="resetEmailConf()"
                               [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                               name="email">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <!-- <div *ngIf="f.email.errors.pattern">Email must be a valid email address.</div> -->
                            <div *ngIf="f.email.errors.duplicate"> Email already in use within the firm.</div>
                        </div>
                        <div *ngIf="f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.pattern">Email must be a valid email address.</div>
                        </div>

                        <div *ngIf="mismatch" class="invalid-feedback" style="display:block;">Email already in use within the firm.</div>
                    </div>

                    <div class=" form-group errorMsg nameSet">
                        <label for="exampleInputEmail2" class="form-label lable-size">Confirm Email <span class="mandate">*</span></label>
                        <input type="email" (input)="restricttextSpace($event);resetEmailConf()"
                               formControlName="emailConfirm"
                               placeholder=""
                               class="form-control member-name-text"
                               id="exampleInputEmail2"
                               aria-describedby="emailHelp"
                               [ngClass]="{ 'is-invalid': submitted && f.emailConfirm.errors}"
                               (paste)="$event.preventDefault()"
                               (keyup)="resetEmailConf()"
                               name="emailConfirm">
                        <div *ngIf="submitted && f.emailConfirm.errors" class="invalid-feedback">
                            <div *ngIf="f.emailConfirm.errors.required">Confirm email is required</div>
                            <div *ngIf="f.emailConfirm.errors.pattern">Email must be a valid email address.</div>
                            <div *ngIf="f.emailConfirm.errors.mismatch && !confmismatch">Confirm email does not match</div>
                        </div>
                        <div *ngIf="confmismatch" class="invalid-feedback">Confirm email does not match.</div>
                    </div>
                    <div class="form-group nameSet">
                        <div class="input-group court assignGrp">
                            <div class="form-control textbox assg" *ngIf="product != 'corporate'">Assign to Groups</div>
                            <div class="form-control textbox assg" *ngIf="product == 'corporate'">Assign to Departments</div>
                            <i class="fa fa-solid fa-plus calimg calitem input-group-append sgroup"  
                            (click)="selMembers()" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="showMemlist">
                <!-- <label for="select" class="lable-size update" *ngIf="product != 'corporate'">Select Groups</label>
                <label for="select" class="lable-size update" *ngIf="product == 'corporate'">Select Departments</label> -->
                  <div class="form-group" style="margin-bottom:10px;">
                  <div class="input-group group-custom-align court" id="search">
                    <input type="text" (input)="restricttextSpace($event)"
                           class="form-control textbox"
                           (keyup)="onSearch($event)"
                           placeholder="Search Groups"
                           value="{{searchText}}" />
                    <img src="assets/img/search.svg"
                         class="calimg calitem input-group-append searchimg">
                  </div>
                </div>

                <!-- <div class="assdiv">
                    <p class="ptext" *ngIf="product != 'corporate'">Assign to Groups</p>&nbsp;<p style="margin-left: auto;" *ngIf="product != 'corporate'"><i class="fa fa-minus" aria-hidden="true"></i></p> 
                    <p class="ptext" *ngIf="product == 'corporate'">Assign to Departments</p>&nbsp;<p style="margin-left: auto;" *ngIf="product == 'corporate'"><i class="fa fa-minus" aria-hidden="true"></i></p> 
                </div> -->

                <div class="form-group " id="selectbx">
                  <div class="input-group group-custom-align court"
                       *ngFor="let grp of groupList | LockFilter: searchText ">
                    <div class="multicheck form-control textbox"
                         [class.active]="selectedGroups.indexOf(grp) > -1">
                      <div class="callselect" [innerHtml]="grp.name"></div>
                      <input class="form-check-inpu calimg calitem input-group-app end checkboxitem"
                             #inputEl
                             type="checkbox"
                             [checked]="selectedGroups.indexOf(grp) > -1"
                             (change)="selectGrp(grp, inputEl.checked)" id="flexCheckDefault">
                    </div>
                  </div>
                </div>
            </div>
            <div class="btnbox btncs row">
              <div class="col-4" *ngIf="!showMemlist"></div>
              <div class="col-3" *ngIf='showMemlist'></div>
              <div class="col-2" *ngIf='showMemlist'>
                <button type="button"
                        class="btn btn-default btnsave btnback"
                        (click)="backToInfo()">Back</button>
              </div>
              <div class="col-2">
                <button type="button"
                        class="btn btn-default btncancel"
                        (click)="cancel()">Cancel</button>
              </div>
              <div class="col-1" *ngIf='showMemlist'>
                <button type="submit" class="btn btn-default btnsave" 
                [disabled]="isSaveDisabled">Done</button>
               </div>
              <div class="col-1" *ngIf='!showMemlist'>
                <button type="submit" class="btn btn-default btnsave" 
                [disabled]="createMemberForm.invalid || createMemberForm.pristine">Save</button>
               </div>
            </div>
        </form>
        </div>
    </div>
</div>

<!-- Confirmation Modal -->
<div class="modal fade" id="modalCancel" tabindex="-1" aria-labelledby="modalCancelLabel" aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content">
          <button type="button" class="btn-close remBtn" data-bs-dismiss="modal" aria-label="Close"></button>
          <div class="modal-body">
              <div class="alertpara"><p class="alertparatxt">Alert</p></div>
          </div>
          <div class="alertext conf">Changes you made will not be saved. <br>Do you want to continue?</div>
          <div class="alertbutton">
              <button class="btn btn-default  alertbtn alertbtnno" data-bs-dismiss="modal" aria-label="Close">No</button>
              <button class="btn btn-default alertbtn alertbtnyes" data-bs-dismiss="modal" aria-label="Close" (click)="onReset()" >Yes</button>
          </div>
      </div>
  </div>
</div>

<!-- Success Modal -->
<div class="modal"
     id="successModal"
     tabindex="-1"
     aria-labelledby="successModalLabel"
     aria-hidden="true"
     style="display:block;"
     *ngIf='successModel'>
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-body">
                        <!-- <div class="iconclose">X</div> -->
                        <button type="button" class="btn-close remBtn" (click)="closeModal()" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div class="alertpara">
                          <p class="alertparatxt">Success</p>
                        </div>
                        <div class="alertext cong" *ngIf="product != 'corporate'">Congratulations!!<br> 
                        You have successfully added <b>{{successMemName}}</b> and assigned to <b>{{successGrpCount}}</b> Groups.
                       </div>
                       <div class="alertext cong" *ngIf="product == 'corporate'">Congratulations!! <br>
                        You have successfully added <b>{{successMemName}}</b> and assigned to <b>{{successGrpCount}}</b> Department.
                      </div>
                        <div class="alertbutton">
                          <button class="btn btn-default alertbtn btnbtn" (click)="successResp('add')">Add Members</button>
                          <button class="btn btn-default alertbtn btnbtn" (click)="successResp('view')">View Changes</button>
                        </div>
          </div>
      </div>
  </div>
</div>
