<div class="container main test width-alignment">
    	<div class="col-12 Individual createGroup">
			<div class="element">Edit Team Member Info</div>
    		<form [formGroup]="createMemberForm" (ngSubmit)="onSubmit()">
    			<div class="row">
    				<div class="col-6">
    					<div class="form-group errorMsg nameSet ">
    						<label for="title" class="lable-size">Name <span class="mandate">*</span></label>
    						<input type="text" formControlName="name" (input)="restricttextSpace($event)"
    						class=" text-size form-control member-name-text"
    						[ngClass]="{ 'is-invalid': submitted && f.name.errors }" id="caseTitle" name="name">
    						<div *ngIf="submitted && f.name.errors" class="invalid-feedback ">
    							<div *ngIf="f.name.errors.required">
    								Name is required
    							</div>
    						</div>
    					</div>
    					<div class="form-group errorMsg nameSet">
    						<label for="comment" class="lable-size">Designation <span class="mandate">*</span></label>
    						<input type="text" formControlName="designation" (input)="restricttextSpace($event)"
    						class=" text-size form-control member-name-text"
    						[ngClass]="{ 'is-invalid': submitted && f.designation.errors }" id="caseTitle"
    						name="designation">
    						<div *ngIf="submitted && f.designation.errors" class="invalid-feedback">
    							<div *ngIf="f.designation.errors.required">
    								Designation is required
    							</div>
    						</div>
    					</div>
    					<div class="form-group errorMsg nameSet">
    						<div class="row">
    							<div class="col-6 ">
    								<label for="select" class="lable-size">Default Currency <span class="mandate">*</span></label>
    								<select formControlName="currency" class="form-select member-name-text nameSet"
    								aria-label="Default select example" style="background-color: #EEEEEE;cursor:pointer"
    								[ngClass]="{ 'is-invalid':submitted && f.currency.errors }" name="currency">
                                    <option *ngFor="let c of currencyList" value="{{c}}">{{c}}</option>
                                </select>
                                <div *ngIf="submitted && f.currency.errors" class="invalid-feedback">
                                    <div *ngIf="f.currency.errors.required">
                                        Currency is required
                                    </div>
                                </div>
    						</div>
    						<div class="col-6 ">
    							<label for="title" class="lable-size">Default Rate <span class="mandate">*</span></label>
    							<input type="text"
            						   formControlName="defaultRate" [ngClass]="{ 'is-invalid': submitted && f.defaultRate.errors }" (input)="restricttextSpace($event);restrictToNumbers($event)"
            						class="nameSet text-size form-control member-name-text" id="caseTitle" name="defaultRate">
    							<div *ngIf="submitted && f.defaultRate.errors" class="invalid-feedback ">
    								<div *ngIf="f.defaultRate.errors.required">
    									Hourly rate is required 
    								</div>
    							</div>
    						</div>
    					</div>
    				</div>
    			</div>
    			<div class="col-6">
    				<div class="form-group errorMsg  nameSet">
    					<label for="exampleInputEmail1" class="form-label lable-size">Email <span class="mandate">*</span></label>
    					<input type="email" (input)="restricttextSpace($event);resetEmailConf()"
                               formControlName="email"
                               class="form-control member-name-text"
    					       id="exampleInputEmail1"
                               aria-describedby="emailHelp"
                               (keyup)="resetEmailConf()"
    					       [ngClass]="{ 'is-invalid': f.email.errors }"
                               name="email">
    					<div *ngIf="f.email.errors" class="invalid-feedback">
    						<div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.pattern">Email must be a valid email address.</div>
    					</div>
    				</div>

    				<div class="form-group errorMsg nameSet">
    					<label for="exampleInputEmail2" class="form-label lable-size"> Confirm Email <span class="mandate">*</span></label>
    					<input type="email"
                               formControlName="emailConfirm"
                               placeholder="" (input)="restricttextSpace($event);resetEmailConf()"
    					       class="form-control member-name-text"
                               id="exampleInputEmail2"
                               aria-describedby="emailHelp"
                               (keyup)="resetEmailConf()"
    					       [ngClass]="{ 'is-invalid': submitted && f.emailConfirm.errors}" name="emailConfirm">
    					<div *ngIf="submitted && f.emailConfirm.errors" class="invalid-feedback">
    						<div *ngIf="f.emailConfirm.errors.required">Confirm Email is required</div>
                            <div *ngIf="f.emailConfirm.errors.pattern">Email must be a valid email address.</div>
                            <div *ngIf="f.emailConfirm.errors.mismatch && !confmismatch">Confirm email does not match</div>
    					</div>
						<div *ngIf="confmismatch" class="invalid-feedback">Confirm email does not match.</div>
    				</div>
    			</div>
    		</div>
    		<div class="btnbox">
    			<button type="button" class="btn btn-default btncancel" (click)="cancel()">Cancel</button>
    			<button type="submit" class="btn btn-default btnsave" [disabled]="createMemberForm.pristine || createMemberForm.invalid">Save</button>
    		</div>
    	    </form>
    </div>
</div>


<!-- Confirmation Modal -->
<div class="modal fade" id="modalCancel" tabindex="-1" aria-labelledby="modalCancelLabel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<button type="button" class="btn-close remBtn" data-bs-dismiss="modal" aria-label="Close"></button>
			<div class="modal-body">
				<div class="alertpara"><p class="alertparatxt">Alert</p></div>
			</div>
			<div class="alertext conf">Changes you made will not be saved. <br>Do you want to continue?</div>
			<div class="alertbutton">
				<button class="btn btn-default alertbtn alertbtnno" data-bs-dismiss="modal" aria-label="Close">No</button>
				<button class="btn btn-default alertbtn alertbtnyes" data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()">Yes</button>
			</div>
		</div>
	</div>
</div>
