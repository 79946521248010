<div class="container update-members main width-alignment">
    <div class="col-12 Individual createGroup">
        <div class="update-group">
        <label for="select" class="lable-size labelaccess" *ngIf="product != 'corporate'">Modify Group Access - {{relname}}</label>
        <label for="select" class="lable-size labelaccess" *ngIf="product == 'corporate'">Update Department Access - {{relname}}</label>
    </div>
        <!--Relation Ipdate Groups-->
        <div class="row">
            <div class="col-3"></div>
            <div class="col-6" *ngIf="product ==='corporate' || product ==='content' || product ==='connect'">
                <div class="form-group" id="selectbx">
                  <div class="input-group group-custom-align court" *ngFor="let grp of groupList" style="margin-bottom:3px;">
                    <div class="multicheck form-control textbox">
                      <div class="callselect" [innerHtml]="grp.name"></div>
                        <input class="form-check-inpu calimg calitem input-group-app end checkboxitem" #inputEl
                        [ngClass]="{'active': selectedIds.includes(grp.id)}" 
                        [checked]="selectedIds.includes(grp.id)" type="checkbox"
                        (change)="selectCorporateGrp(grp, inputEl.checked)"> 
                      </div>
                  </div>
                </div>
            </div>
            <div class="col-12 createGroup">
              <div class="row" *ngIf="product !=='corporate' && product !=='content' && product !=='connect'">
                <div class="col-6 matterpadding">
                  <h5 class="float-left grpFont" *ngIf ="product !='corporate'">Selected Group(s)</h5>
                  <h5 class="float-left grpFont" *ngIf ="product =='corporate'">Selected Department(s)</h5>
                  <div class="selectedone">
                    <div class="form-group group-custom-align" *ngFor="let group of selectedGroups">
                      <div class="input-group multicheck court" id="selectedgroup">
                        <div class="form-control textbox" [innerHtml]="group.name"></div>
                        <i class="fa fa-regular fa-circle-xmark calimg calitem input-group-append sgroup" aria-hidden="true"
                        (click)="removeGroup(group);searchText = ''"></i>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="col-6 matterpadding">
                  <h5 class="float-left grpFont" *ngIf ="product !='corporate'">Assign to Group(s)<span class="mandate">*</span></h5> 
                  <h5 class="float-left grpFont" *ngIf ="product =='corporate'">Assign to Department(s)<span class="mandate">*</span></h5> 
                  <div class="col-3" *ngIf="!isEdit">
                    <div class="form-group" id="selectbx">
                      <div class="input-group court" id="search">
                        <!-- <div class="multicheck form-control textbox">
                          <label class="callselect">Select All</label>
                          <input class="form-check-inpu calimg calitem input-group-append checkboxitem" type="checkbox" value=""
                            id="selectAll" (change)="selectAll($event)">
                        </div> -->
                      </div>
                    </div>
                  </div>
                  
                  <div class="form-group">
                    <div class="input-group group-custom-align court" id="search" *ngIf ="product !='corporate'">
                      <input type="text" class="form-control textbox searchtext" placeholder="Search Group" [(ngModel)]="searchText" (keyup)="keyup()" />
                      <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
                    </div>
                    <div class="input-group group-custom-align court" id="search" *ngIf ="product =='corporate'">
                      <input type="text" class="form-control textbox searchtext" placeholder="Search Department" [(ngModel)]="searchText" (keyup)="keyup()" />
                      <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
                    </div>
                  </div>
          
                  <div class="form-group" id="selectbx">
                    <div class="input-group group-custom-align court matterpadding" id="search"
                      *ngFor="let group of filteredData | filter:searchText">
                      <div class="multicheck form-control textbox">
                        <div class="callselect" [innerHtml]="group.name"></div>
                          <input class="form-check-inpu calimg calitem input-group-app end checkboxitem" 
                          #inputEl type="checkbox" (change)="selectGrp(group, inputEl.checked)"> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <!--Relation Update Groups-->
     <div class="btnbox">
        <button type="button" class="btn btn-default btncancel" (click)= "cancel()">Cancel</button>
        <button type="button" class="btn btn-default btnsave" [disabled]="isSaveDisabled" (click)="confirmSave()">Save</button>
     </div>
</div>
<div class="modal"
     *ngIf='showConfirm'
     style="display:block;" 
     tabindex="-1">
  <div class="modal-dialog">
      <div class="modal-content">
        
              <button type="button"
                      class="btn-close"
                      data-bs-dismiss="modal" (click)="showConfirm = false;" aria-label="Close"></button>

          <div class="modal-body">
              <div class="alertpara">
                  <p class="alertparatxt">Confirm</p>
                </div>
          </div>
          <div class="alertext" *ngIf="product != 'corporate'" style="height:130px;">
            Are you sure you want to modify Group access for
            <strong>{{reldata.name}}</strong>.
          </div>

          <div class="alertext" *ngIf="product == 'corporate'" style="height:130px;">
            Are you sure you want to modify Department access for
            <strong>{{reldata.name}}</strong>.
          </div>
          <div class="alertbutton">
              <button class="btn btn-default  alertbtn alertbtnno"
                      data-bs-dismiss="modal"
                      (click)="showConfirm = false;"
                      aria-label="Close">No</button>
              <button class="btn btn-default alertbtn alertbtnyes"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      (click)="save()">Yes</button>
          </div>
      </div>
  </div>
</div>

<!-- update group access dialog-->
<div *ngIf="editDoc" class="modal fade editInfoModal1" id="editInfoModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-xl">
    <div class="modal-content">

      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="remGroups();searchText = ''"></button>

      <div class="modal-body">
        <div class="col-12  editdoc modal-content">

            <div class="row width-alignment">

              <label for="title" class="lable-size updateGroup" *ngIf="product !='corporate'">Update Group</label>
              <label for="title" class="lable-size updateGroup" *ngIf="product =='corporate'">Update Department</label>

              <div class="groupContent" *ngIf="product !='corporate'">
                <i class="fa fa-exclamation-circle" style="color: red;font-size:16px" aria-hidden="true"></i> &nbsp;
                This <b style="font-weight: 900;">'{{editDoc.name}}'</b> group currently contains <b style="font-weight: 900;color: #004D87;">{{this.removegrpId?.documents}} Documents.</b> Before updating, please assign them to another active group.
              </div>
              <div class="groupContent" *ngIf="product =='corporate'">
                <i class="fa fa-exclamation-circle" style="color: red;font-size:16px" aria-hidden="true"></i> &nbsp;
                This <b style="font-weight: 900;">'{{editDoc.name}}'</b> department currently contains <b style="font-weight: 900;color: #004D87;">{{this.removegrpId?.documents}} Documents.</b> Before updating, please assign them to another active department.
              </div>

              <div class="col-5">
                <div class="form-group nameSet listedGroup">
                  <h5 class="float-left grpFont" *ngIf="product !='corporate'">Selected Group(s)</h5>
                  <h5 class="float-left grpFont" *ngIf="product =='corporate'">Selected Department(s)</h5>
                </div>
            
                <div class="form-group" id="selectbx">
                  <div class="input-group group-custom-align court matterpadding" *ngFor="let group of selectedtoupdateGroups"
                    id="selectedgroup">
                    <div class="multicheck form-control textbox">
                      <div class="callselect" [innerHtml]="group.name"></div>
                      <i class="fa fa-regular fa-circle-xmark calimg calitem input-group-app end checkboxitem circlemark" aria-hidden="true"
                      (click)="removeDialogGroup(group)"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2"></div>
              <div class="col-5">
                <div class="form-group nameSet listedGroup">
                  <h5 class="float-left grpFont" *ngIf="product !='corporate'">Assign to another active Group(s)<span class="mandate">*</span></h5>
                  <h5 class="float-left grpFont" *ngIf="product =='corporate'">Assign to another active Department(s)<span class="mandate">*</span></h5>

                  <div class="form-group">
                    <div class="input-group group-custom-align court" id="search" *ngIf ="product !='corporate'">
                      <input type="text" class="form-control textbox searchtext" placeholder="Search Group" [(ngModel)]="searchText" (keyup)="keyup()" />
                      <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
                    </div>
                    <div class="input-group group-custom-align court" id="search" *ngIf ="product =='corporate'">
                      <input type="text" class="form-control textbox searchtext" placeholder="Search Department" [(ngModel)]="searchText" (keyup)="keyup()" />
                      <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
                    </div>
                  </div>
                </div>
            
                <div class="form-group" id="selectbx">
                  <div class="input-group group-custom-align court matterpadding" id="search"
                    *ngFor="let group of filteredData |filter:searchText">
                    <div class="multicheck form-control textbox">
                      <div class="callselect" [innerHtml]="group.name"></div>
                      <input class="form-check-inpu calimg calitem input-group-app end checkboxitem" 
                      #inputEl type="checkbox" id="flexCheckDefault"
                      (change)="selecttoUpdateGroup(group, inputEl.checked)">
                    </div>
                  </div>
                </div>
            
              </div>
            </div>
            <div class="btnbox dialogbtn">
              <button type="button" class="btn btn-default test btncancel" data-bs-dismiss="modal" aria-label="Close"
              (click)="remGroups();searchText = ''">Cancel</button>
              <button type="submit" class="btn btn-default btnsave" data-bs-dismiss="modal" aria-label="Close"
              (click)="deleteGroup();searchText = ''" [disabled]="selectedtoupdateGroups.length === 0">Delete</button>
            </div>
        </div>
      </div>
    </div>

  </div>
</div>
<!-- update group access dialog-->