<!--component html goes here -->
<div class="col-sm-12 compswitch ">
  <div class="row">
    <div class="col-sm-3 col-xs-1"></div>
    <div class="col-sm-2 col-xs-3 round-icon">
      <div>
        <p class="selectprior optionhigh2 casetito" [matTooltip]="data.Title" matTooltipPosition="after"> {{truncateString(data.Title)}} </p> 
        <div class="flex-container">
          <!-- <div class="selectpriorGrp optionhigh2 flexo" *ngFor="let item of groupName"> {{item}} </div>  -->
          <b class="selGroup" *ngIf="product !== 'corporate'">Selected Groups:</b>
          <b class="selGroup" *ngIf="product === 'corporate'">Selected Departments:</b>
          <div class="divShow" *ngFor="let item of (showAllItems ? groupName : groupName.slice(0, 2)); let i = index">
            {{ item }}
            <span *ngIf="groupName.length == 1"> </span>
            <span *ngIf="groupName.length > 1 && i < (showAllItems ? groupName.length : 2) - 1">, </span>
          </div>
          <div *ngIf="groupName.length > 2" class="view-more-less" (click)="toggleView()">
            <a href="javascript:void(0)">{{ showAllItems ? 'View Less' : 'View More' }}</a>
          </div>
        </div>
      </div>
    </div>

    
  </div>
  <div class="col-12 Individual createGroup">
    <div class="row">
      <div class="col-6 matterpadding">
        <h5 class="float-left grpFont">Selected Team Member(s)</h5>
        <div class="selectedone">
          <div class="form-group group-custom-align" *ngFor="let teammember of selectedTeammembers">
            <div class="input-group multicheck court" id="selectedgroup">
              <div class="form-control textbox textAl" [innerHtml]="teammember.name"></div>
              <i class="fa fa-regular fa-circle-xmark calimg calitem input-group-append sgroup" aria-hidden="true"
                (click)="removeTeammember(teammember)"></i>

            </div>
          </div>
        </div>
      </div>
      <div class="col-6 matterpadding">
        <!--<div class="col-3">
          <h5>Assign Team Member(s)</h5>
        </div>-->
        <h5 class="float-left grpFont">Assign Team Member(s)</h5> 
        <h5 class="float-right lop" *ngIf="isSelectAllVisible">
          <label class="callselect checkAll">Select All</label>&nbsp;
          <input type="checkbox" value="" 
          [checked]="filteredData?.length > 0 && selectedTeammembers.length === filteredData.length" 
          id="selectAll" (change)="selectAll($event)">
        </h5>
        <!-- <div class="col-3">
          <div class="form-group" id="selectbx">
            <div class="input-group court" id="search">
              <div class="multicheck form-control textbox" *ngIf="isSelectAllVisible">
                <label class="callselect selAl">Select All</label>
                <input class="form-check-inpu calimg calitem input-group-append checkboxitem" type="checkbox" value=""
                  id="selectAll" (change)="selectAll($event)">
              </div>
            </div>
          </div>
        </div> -->
        <div class="form-group ">

          <div class="input-group group-custom-align court" id="search">
            <input type="text" class="form-control textbox searchtextcr" placeholder="Search Team Member" [(ngModel)]="searchText" (keyup)="keyup()" />
            <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
          </div>
        </div>
        <div class="form-group " id="selectbx ">
          <div class="input-group group-custom-align court matterpadding" id="search"
            *ngFor="let teammember of teammembersList |filter:searchText">
            <div class="multicheck form-control textbox">
              <div class="callselect textAl" [innerHtml]="teammember.name"></div>
              <input class="form-check-inpu calimg calitem input-group-app
                                      end checkboxitem" #inputEl type="checkbox" id="flexCheckDefault"
                (change)="selectTeammember(teammember, inputEl.checked)">
            </div>
          </div>
        </div>
      </div>
      <div class="btnbox">
        <button type="reset" class="btn btn-default btncancel btnrightitem" (click)="OnCancel()">Cancel</button>
        <button type="submit" class="btn btn-primary btnsave savecls" (click)="saveTeammembers()">Next</button>
      </div>
    </div>
  </div>

</div>