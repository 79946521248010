<div class="row main width-alignment">
  <div class="col-6">
    <div class="custusername tab-margin fontweight">
      List of Legal Matters
    </div>
  </div>

  <div class="col-6">
    <div class="form-group name-set float-right">
      <!--Search box-->
      <div class="input-group group-custom-align court padded" id="search">
        <input type="text" class="form-control textbox" placeholder="Search Matter" [(ngModel)]="searchText" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]|^ /g,'')"/>
        <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
      </div>
      <!-- Dropdown - Internal/External -->
      <div *ngIf="product == 'corporate'">
        <select class="form-select calendorselect" (change)="onOptionSelect($event)">
          <option [selected]="i.value == selectedOption" *ngFor="let i of options ">{{i.name}}</option>
        </select>
      </div>
    </div>
  </div>

</div>

<div class="row main width-alignment">
  <div class="col-sm-12 gridtable ">
    <table class="table table-borderless tblgrid">
      <thead>
        <tr class="theader">
          <th>
            <p class="thheadname"> Name</p>
            <img src="assets/img/SORT.PNG" class="sortico" (click)="sort('title')">
          </th>
          <th>
            <p class="thheadname"> Type</p>
            <img src="assets/img/SORT.PNG" class="sortico" (click)="sort('caseType')">
          </th>
          <th>
            <p class="thheadname"> Created On</p>
            <img src="assets/img/SORT.PNG" class="sortico" (click)="sort('date_of_filling')">
          </th>
          <th *ngIf="this.selectedOption=='External Matters'">
            <p class="thheadname"> External Counsels</p>
            <img src="assets/img/SORT.PNG" class="sortico" (click)="sort('owner')">
          </th>
          <th>
            <p class="thheadname"> Status</p>
            <img src="assets/img/SORT.PNG" class="sortico" (click)="sort('status')">
          </th>
          <th>
            <p class="thheadname" *ngIf = "product !='corporate'"> Groups</p>
            <p class="thheadname" *ngIf = "product =='corporate' && this.selectedOption != 'External Matters'"> Departments</p>
            <img src="assets/img/SORT.PNG" *ngIf = "this.selectedOption != 'External Matters'" class="sortico" (click)="sort('groups')">
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr class="rone veiw-items" *ngFor="let legalMatter of legalMatters |filter:searchText">
          <td>{{legalMatter.title}}</td>
          <td>{{legalMatter.caseType}}</td>
          <td>{{legalMatter.created_on}}</td>
          <td *ngIf="this.selectedOption=='External Matters'">{{legalMatter.owner?.firm_name}}</td>
          <td>{{legalMatter.status}}</td>

          <!--no dept for external-->
          <td *ngIf = "this.selectedOption != 'External Matters'" (mouseover)="onMouseOver(legalMatter?.groups)" [matTooltip]="hoveredGroups">
            {{legalMatter?.groups[0]?.name}} <br/> {{legalMatter?.groups[1]?.name}}
          </td>
          <td *ngIf = "this.selectedOption == 'External Matters'"></td>
          <!--need to get back once we get group info-->

          <td>
            <div class="dropdown" *ngIf="this.selectedOption == 'External Matters'">
              <button class="btn btn-primary actionbtn dropdown" aria-expanded="false" [ngClass]="{'disabled': legalMatter.isdisabled}"
                (click)="loadViewDetails(legalMatter,'external')">More Details</button>
            </div>

            <div class="dropdown" *ngIf="this.selectedOption != 'External Matters'"><!--class="dropdown"-->
              <!-- <button class="btn btn-primary dropdown-toggle actionbtn" data-bs-toggle="dropdown" aria-expanded="false" 
                [ngClass]="{'disabled': legalMatter.isdisabled || !legalMatter.is_editable}">Action</button>
              <ul class="dropdown-menu custom-dropdown">
                <li>
                  <a class="dropdown-item" (click)="loadViewDetails(legalMatter,'internal')">View Details</a>
                </li>
                <li>
                  <a class="dropdown-item" (click)="loadEditMatterInfo(legalMatter)">Edit Matter Info</a>
                </li>
                <li>
                  <a *ngIf="product !='corporate'" class="dropdown-item" (click)="updateGroups(legalMatter)">Update Group(s)</a>
                  <a *ngIf="product =='corporate'" class="dropdown-item" (click)="updateGroups(legalMatter)">Update
                    Department(s)</a>
                </li>
                <li *ngIf="legalMatter.status.toLowerCase() == 'active'">
                  <a class="dropdown-item" (click)="updateMatterStatus(legalMatter,'Closed')">Close Matter</a>
                </li>
                <li *ngIf="legalMatter.status.toLowerCase()  == 'closed'">
                  <a class="dropdown-item" (click)="updateMatterStatus(legalMatter,'Active')">Reopen Matter</a>
                </li>
                <li>
                  <a class="dropdown-item" (click)="delete(legalMatter)">Delete</a>
                </li>
              </ul> -->

              <button mat-button [matMenuTriggerFor]="menu" class="btn btn-primary dropdown-toggle actionbtn matBtn"
                data-bs-toggle="dropdown" aria-expanded="false" [ngClass]="{'disabled': legalMatter.isdisabled || !legalMatter.is_editable}">Action</button>
              
              <mat-menu class="dropdown-menu custom-dropdown" #menu="matMenu">
                <button mat-menu-item (click)="loadViewDetails(legalMatter,'internal')">View Details</button>
                <button mat-menu-item (click)="loadEditMatterInfo(legalMatter)">Edit Matter Info</button>
              
                <button mat-menu-item *ngIf="product !='corporate'" (click)="updateGroups(legalMatter)">Update Group(s)</button>
                <button mat-menu-item *ngIf="product =='corporate'" (click)="updateGroups(legalMatter)">Update Department(s)</button>
              
                <button mat-menu-item *ngIf="legalMatter.status.toLowerCase() == 'active'" (click)="updateMatterStatus(legalMatter,'Closed')">Close Matter</button>
                <button mat-menu-item *ngIf="legalMatter.status.toLowerCase()  == 'closed'" (click)="updateMatterStatus(legalMatter,'Active')">Reopen Matter</button>
              
                <button mat-menu-item (click)="delete(legalMatter)">Delete</button>
              </mat-menu>
    
            </div>

          </td>
        </tr>
      </tbody>
    </table>

    <!-- <div class="row">
      <div class="col-6"> <label for="">Showing <span>{{fromCount}}</span>to <span>{{toCount}}</span> of <span *ngIf="legalMatters">{{legalMatters.length}}</span> entries</label></div>
      <div class="col-6">
          <pagination-controls (pageChange)="p= $event"
              [maxSize]="3"
              [responsive]="true" (pageChange)="pageChanged($event)" >
          </pagination-controls></div>
    </div> -->

  </div>
  <ngx-spinner  size="medium" type="ball-clip-rotate"></ngx-spinner>
</div>
