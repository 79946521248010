<div class="container main test width-alignment">
    <!-- <div *ngIf="successMsg">
        {{successMsg}} -->
    <!-- </div> -->
    <div class="col-12 Individual createGroup">
        <div class="row">
            <div class="col-sm-4 col-xs-1"></div>
            <div class="col-sm-2 col-xs-3 round-icon">
              <div class="steprow">
                <a href="javascript: void(0)"
                   class="stepone"
                   [ngClass]="{'green': showMemlist }">
                </a>
              </div>
              <div><p class="ptext">Team Member Info</p> </div>
            </div>
            <div class="col-sm-2 col-xs-3 round-icon">
              <div class="steprow">
                <a href="javascript: void(0)"
                   class="stepone"
                   [ngClass]="{'green': showMemlist }">
                </a>
              </div>
              <div>
                <p class="ptext" *ngIf="product != 'corporate'">Assign to Group</p> 
                <p class="ptext" *ngIf="product == 'corporate'">Assign to Department</p> 
             </div>
            </div>
            <div class="col-sm-3 col-xs-3"> </div>
        </div>
        <h5>&nbsp;</h5>
        <form [formGroup]="createMemberForm" (ngSubmit)="onSubmit()">
            <div class="row" *ngIf='showForm'>
                <div class="col-6">
                    <div class="form-group errorMsg nameSet ">
                        <label for="title" class="lable-size">Name:</label>
                        <input type="text" (input)="restricttextSpace($event)"
                               formControlName="name"
                               class=" text-size form-control member-name-text"
                               [ngClass]="{ 'is-invalid': submitted && f.name.errors }" id="caseTitle"
                               name="name">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback ">
                            <div *ngIf="f.name.errors.required">
                                Name is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group errorMsg nameSet">
                        <label for="comment" class="lable-size">Designation:</label>
                        <input type="text" (input)="restricttextSpace($event)"
                               formControlName="designation"
                               class=" text-size form-control member-name-text"
                               [ngClass]="{ 'is-invalid': submitted && f.designation.errors }"
                               id="caseTitle"
                               name="designation">
                        <div *ngIf="submitted && f.designation.errors" class="invalid-feedback">
                            <div *ngIf="f.designation.errors.required">
                                Designation is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group errorMsg nameSet">
                        <div class="row">
                            <div class="col-6 ">
                                <label for="select" class="lable-size">Default Currency</label>
                                <select formControlName="currency"
                                        class="form-select member-name-text nameSet"
                                        aria-label="Default select example"
                                        [ngClass]="{ 'is-invalid':submitted && f.currency.errors }"
                                        name="currency">
                                    <option *ngFor="let c of currencyList">{{c}}</option>
                                </select>
                                <div *ngIf="submitted && f.currency.errors" class="invalid-feedback">
                                    <div *ngIf="f.currency.errors.required">
                                        Currency is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 ">
                                <label for="title" class="lable-size">Default Rate:</label>
                                <input type="text" (input)="restricttextSpace($event)"
                                       formControlName="defaultRate"
                                       class="nameSet text-size form-control member-name-text"
                                       [ngClass]="{ 'is-invalid': submitted && f.defaultRate.errors }"
                                       id="caseTitle"
                                       name="defaultRate">
                                <div *ngIf="submitted && f.defaultRate.errors" class="invalid-feedback ">
                                    <div *ngIf="f.defaultRate.errors.required">
                                        Hourly rate is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class=" form-group errorMsg  nameSet">
                        <label for="exampleInputEmail1" class="form-label lable-size">Email</label>
                        <input type="email" (input)="restricttextSpace($event)"
                               formControlName="email"
                               class="form-control member-name-text"
                               id="exampleInputEmail1"
                               aria-describedby="emailHelp"
                               (keyup)="resetEmailConf()"
                               [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                               name="email">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.pattern">Email must be a valid email address.</div>
                            <div *ngIf="f.email.errors.duplicate"> Email already in use within the firm.</div>
                        </div>
                        <div *ngIf="mismatch" class="invalid-feedback" style="display:block;">
                            Email already in use within the firm.</div>
                    </div>

                    <div class=" form-group errorMsg nameSet">
                        <label for="exampleInputEmail2" class="form-label lable-size"> Confirm Email</label>
                        <input type="email" (input)="restricttextSpace($event)"
                               formControlName="emailConfirm"
                               placeholder=""
                               class="form-control member-name-text"
                               id="exampleInputEmail2"
                               aria-describedby="emailHelp"
                               [ngClass]="{ 'is-invalid': submitted && f.emailConfirm.errors}"
                               (paste)="$event.preventDefault()"
                               (keyup)="resetEmailConf()"
                               name="emailConfirm">
                        <div *ngIf="submitted && f.emailConfirm.errors" class="invalid-feedback">
                            <div *ngIf="f.emailConfirm.errors.required">Confirm email is required</div>
                            <div *ngIf="f.emailConfirm.errors.pattern">Email must be a valid email address.</div>
                            <div *ngIf="f.emailConfirm.errors.mismatch">Confirm email does not match</div>
                        </div>
                    </div>
                    <div class="form-group nameSet">
                        <div class="input-group court" (click)="selMembers()">
                            <div class="form-control textbox" *ngIf="product != 'corporate'">Assign Group</div>
                            <div class="form-control textbox" *ngIf="product == 'corporate'">Assign Department</div>
                            <i class="fa fa-solid fa-plus calimg calitem input-group-append sgroup"
                                aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="showMemlist">
                  <div class="form-group" style="margin-bottom:10px;">
                  <div class="input-group group-custom-align court" id="search">
                    <input type="text" (input)="restricttextSpace($event)"
                           class="form-control textbox"
                           (keyup)="onSearch($event)"
                           placeholder="Search Members"
                           value="{{searchText}}" />
                    <img src="assets/img/search.svg"
                         class="calimg calitem input-group-append searchimg">
                  </div>
                </div>
                <div class="form-group " id="selectbx">
                  <div class="input-group group-custom-align court"
                       *ngFor="let grp of groupList | LockFilter: searchText ">
                    <div class="multicheck form-control textbox"
                         [class.active]="selectedGroups.indexOf(grp) > -1">
                      <div class="callselect" [innerHtml]="grp.name"></div>
                      <input class="form-check-inpu calimg calitem input-group-app end checkboxitem"
                             #inputEl
                             type="checkbox"
                             [checked]="selectedGroups.indexOf(grp) > -1"
                             (change)="selectGrp(grp, inputEl.checked)" id="flexCheckDefault">
                    </div>
                  </div>
                </div>
            </div>
            <div class="btnbox row">
              <div class="col-4" *ngIf="!showMemlist"></div>
              <div class="col-3" *ngIf='showMemlist'></div>
              <div class="col-2" *ngIf='showMemlist'>
                <button type="button"
                        class="btn btn-default btnsave btnback"
                        (click)="backToInfo()">Back</button>
              </div>
              <div class="col-2">
                <button type="button"
                        class="btn btn-default btncancel"
                        data-bs-toggle="modal"
                        data-bs-target="#modalCancel">Cancel</button>
              </div>
              <div class="col-1">
                <button type="submit" class="btn btn-default btnsave">Save</button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal fade"
     id="modalCancel"
     tabindex="-1"
     aria-labelledby="modalCancelLabel"
     aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content">
              <button type="button" class="btn-close remBtn" data-bs-dismiss="modal" aria-label="Close"></button>
          <div class="modal-body">
              <div class="alertpara">
                  <p class="alertparatxt"> Confirmation</p>
                </div>
          </div>
          <div class="alertext">Changes you made will not be saved. Do you want to continue?</div>
          <div class="alertbutton">
              <button class="btn btn-default  alertbtn alertbtnno"
                      data-bs-dismiss="modal"
                      aria-label="Close">No</button>
              <button class="btn btn-default alertbtn alertbtnyes"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      (click)="onReset()" >Yes</button>
          </div>
      </div>
  </div>
</div>
<div class="modal"
     id="successModal"
     tabindex="-1"
     aria-labelledby="successModalLabel"
     aria-hidden="true"
     style="display:block;"
     *ngIf='successModel'>
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-body">
                        <!-- <div class="iconclose">X</div> -->
                        <div class="alertpara">
                          <p class="alertparatxt"> Success</p>
                        </div>
                        <div class="alertext" *ngIf="product != 'corporate'">Congratulations!! 
                          You have successfully added {{successMemName}} and assigned to {{successGrpCount}} Groups.
                       </div>
                       <div class="alertext" *ngIf="product == 'corporate'">Congratulations!! 
                        You have successfully added {{successMemName}} and assigned to {{successGrpCount}} Department.
                      </div>
                        <div class="alertbutton">
                          <button class="btn btn-default alertbtn" (click)="successResp('add')">Add Members</button>
                          <button class="btn btn-default alertbtn" (click)="successResp('view')">View Changes</button>
                        </div>
          </div>
      </div>
  </div>
</div>
