<!-- Main content -->
<div class="container-fluid width-alignment">
  <div class="row">
    <!-- steps container -->
    <div class="row main stepitem compstep centro">
      <div class="col-sm-12 compswitch ">
        <div class="row">
          <div class="col-sm-1 col-xs-1"></div>
          <div class="col-sm-2 col-xs-3">
            <div class="steprow">
              <a (click)="selectedTabItem ='matter-info'" class="stepone stepideal"
                [ngClass]="{'yallow' : selectedTabItem=='matter-info','green':matterInfo }">
                <img src="assets/img/stepone.svg" class="stepimg">
              </a>
            </div>
            <div>
              <p class="stptxt">Matter Info</p>
            </div>
          </div>
          
          <!-- Clients - notCorporate -->
          <div class="col-sm-2 col-xs-3" *ngIf="product != 'corporate'">
            <div class="steprow">
              <a (click)="selectedTabItem ='matter-clients'" class="stepone stepideal"
                [ngClass]="{'yallow' : selectedTabItem=='matter-clients','green':clients?.length>0 || tempClients?.length>0 || selectedClients}"
                [class.disabled]="!matterInfo">
                <img src="assets/img/clients.svg" class="stepimg">
              </a>
            </div>
            <div *ngIf="product != 'corporate'">
              <p class="stptxt">Client(s)</p>
            </div>
            <div *ngIf="product == 'corporate'">
              <p class="stptxt" style="margin-left: -25px;">External Counsel(s)</p>
            </div>
          </div>
          <!-- Clients - notCorporate -->
          <!-- Groups - notCorporate -->
          <div class="col-sm-2 col-xs-3" *ngIf="product != 'corporate'">
            <div class="steprow">
              <a (click)="selectedTabItem ='matter-groups'" class="stepone stepideal"
                [ngClass]="{'yallow' :selectedTabItem == 'matter-groups','green':groups.length>0}"
                [class.disabled]="clients.length == 0">
                <img src="assets/img/groups.svg" class="stepimg">
              </a>
            </div>
            <div *ngIf="product != 'corporate'">
              <p class="stptxt">Group(s)</p>
            </div>
            <div *ngIf="product == 'corporate'">
              <p class="stptxt" style="margin-left: -20px;">Department(s)</p>
            </div>
          </div>
          <!-- Groups - notCorporate -->

          <!-- Groups - Corporate -->
          <div class="col-sm-2 col-xs-3" *ngIf="product == 'corporate'">
            <div class="steprow">
              <a (click)="selectedTabItem ='matter-groups'" class="stepone stepideal"
                [ngClass]="{'yallow' :selectedTabItem == 'matter-groups','green':groups.length>0}"
                [class.disabled]="!matterInfo">
                <img src="assets/img/groups.svg" class="stepimg">
              </a>
            </div>
            <div>
              <p class="stptxt" style="margin-left: -20px;">Department(s)</p>
            </div>
          </div>
          <!-- Groups - Corporate -->
          <!-- Clients - Corporate -->
          <div class="col-sm-2 col-xs-3" *ngIf="product == 'corporate'">
            <div class="steprow">
              <a (click)="selectedTabItem ='matter-clients'" class="stepone stepideal"
                [ngClass]="{'yallow' : selectedTabItem=='matter-clients','green':clients?.length>0 || tempClients?.length>0 || selectedClients}"
                [class.disabled]="groups.length == 0"><!--selectedClients?.length>0-->
                <img src="assets/img/clients.svg" class="stepimg">
              </a>
            </div>
            <div>
              <p class="stptxt" style="margin-left: -25px;">External Counsel(s)</p>
            </div>
          </div>
          <!-- Clients - Corporate -->

          <div class="col-sm-2 col-xs-3">
            <div class="steprow">
              <a (click)="selectedTabItem ='matter-team-member'" class="stepone stepideal"
                [ngClass]="{'yallow' : selectedTabItem=='matter-team-member','green':(teammembers.length>0 || highLightTeamMem) }"
                [class.disabled]="groups.length == 0">
                <img src="assets/img/teams.svg" class="stepimg">
              </a>
            </div>
            <div>
              <p class="stptxt stepm">Team Member(s)</p>
            </div>
          </div>
          <div class="col-sm-2 col-xs-3">
            <div class="steprow">
              <a (click)="selectedTabItem ='matter-documents'" class="stepone stepideal"
                [ngClass]="{'yallow' : selectedTabItem=='matter-documents','green':docsData.length>0 }"
                [class.disabled]="groups.length == 0">
                <img src="assets/img/documents.svg" class="stepimg">
              </a>
            </div>
            <div>
              <p class="stptxt">Document(s)</p>
            </div>
          </div>
          <div class="col-sm-1 col-xs-1"></div>
        </div>
      </div>
    </div>

  </div>
</div>
<div *ngIf="selectedTabItem == 'matter-info'">
  <matter-info [data]="matterInfo" (childButtonEvent)="receiveMatterIngo($event)"></matter-info>
</div>
<div *ngIf="selectedTabItem == 'matter-clients'">
  <matter-clients [data]="InfotoOtherTabs" [clients]="clients" [groups]="groups"  
   (temporaryClientsEvent)="temporaryClients($event)"
    (selectedClientsEvent)="selectedClient($event)"></matter-clients>
</div>
<div *ngIf="selectedTabItem == 'matter-groups'">
  <matter-groups [data]="InfotoOtherTabs" [groups]="groups" [clients]="clients"
    (selectedGroupsEvent)="selectedGroup($event)"></matter-groups>
</div>
<div *ngIf="selectedTabItem == 'matter-team-member'">
  <matter-team-members [data]="InfotoOtherTabs" [teammembers]="teammembers" [groups]="groups" [clients]="clients"
  (selectedTmsEvent)="selectedTeammemberes($event)"></matter-team-members>
</div>
<div *ngIf="selectedTabItem == 'matter-documents'">
  <matter-documents [data]="InfotoOtherTabs" [groups]="groups" [clients]="clientsData" [corporate]="corpData"
    (selectedDocumentsEvent)="selectedDocuments($event)"></matter-documents>
</div>
<!-- <div class="btnbox" *ngIf="selectedTabItem == 'matter-documents'">
  <button type="reset" class="btn btn-default btncancel">Cancel</button>
  <button type="submit" class="btn btn-default btnsave">Submit</button>
</div> -->