<!--component html goes here -->

<h1 mat-dialog-title>Confirmation</h1>
<div mat-dialog-content>
  <p>What's your favorite animal?</p>
  <mat-form-field appearance="fill">
    <mat-label>Favorite Animal</mat-label>
    <input matInput [(ngModel)]="data.name">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">OK</button>
</div>