<!-- OPEN DOCUMENT DIALOG -->
<div class="overviewDialog">
    <form>
    <div class="closeDialog">
        <i class="fa fa-times closeBtn" (click)="closeDialog()" aria-hidden="true"></i>
    </div>

    <div class="openDialog">
        <div class="form-group">
            <!-- <label for="title" class="lableTitle openText">Open Document&nbsp;<img src="assets/img/SORT.PNG" class="sortico" (click)="sortingFile('documentname')"></label> -->
            <label for="title" class="lableTitle openText">Open a Document</label>
        </div>
           <hr>

        <div class="contianer row form-group">
            <div class="col-sm-6" *ngFor="let d of documents| filter:searchText">
              <div class="innerCont" (click)="ondocumentClick(d.docid,d.documentname)"><!-- ;child.extractionData(); -->
                      <div class="contBox hoverDoc">
                        <!-- <p><i class="fa fa-file-text ico" aria-hidden="true"></i>&nbsp;{{truncateString(d.documentname)}} </p> -->
                        <p><i class="fa fa-file-text ico" aria-hidden="true"></i>&nbsp;{{d.documentname}}</p>
                         <!-- <p class="hoverTime"> {{d.updatedon?.$date}}</p> -->
                         <p class="hoverTime"> {{d.updatedon?.$date | date :"dd MMM, yyyy -- h:mm a"}}</p> 
                      </div>
              </div>
            </div>
        </div>

    </div>
    </form>    
</div>

<!-- <app-latexblock #child></app-latexblock> -->