<div class="row view-document">
    <div class="col-12">
        <div class="row">
            <div class="col-6">
                <p class="docview">Document Viewer</p>
                <div class="watermark">
                    <div *ngIf="imageUrl" class="safeurl">
                        <!-- <img width="100%" [src]="imageUrl" class="image"> -->
                        <iframe height="100%" width="100%" [src]="imageUrl"></iframe>
                        <!-- <ngx-doc-viewer [url]="imageUrl" viewer="google" style="width:100%;height:80vh;"></ngx-doc-viewer> -->
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="watermark-left">
                    <div class="custusername tab-margin fontweight">
                        Add Watermark
                    </div>
                    <div class="form-group name-set">
                        <label for="title" class="fontbold tab-margin">Select Watermark File <span
                                class="mandate">*</span></label>              
                     
                        <!-- <div class="input-group court">
                            <input type="file" id="file" class="myclass file-selector-button" class="form-control textbox"
                                placeholder="Search by name" accept=".pdf" (change)="onFileSelected($event);onSelectFile($event)"/>
                            <button id="fileUpload" for="file" class="input-group-append appendadd btn-browse"
                            >Browse</button>
                        </div> -->

                        <div class="input-group court">
                            <input type="file" #file id="file" class="myclass file-selector-button" class="form-control textbox"
                                placeholder="Search by name" accept=".pdf" (change)="onFileSelected($event);onSelectFile($event)"/>
                            <button id="fileUpload" for="file" class="input-group-append appendadd btn-browse"
                            (click)="file.click()">Browse</button>
                        </div>
                        <h6 class="mandate tab-margin spf">Add a single page pdf file</h6>
                    </div>
                </div>
                <div class="btnbox">
                    <button type="reset" class="btn btn-default btncancel" (click)="cancel()">Cancel</button>
                    <button type="submit" (click)="save()" [ngClass]="{'disabled': saveDoc === false }" class="btn btn-default btnsave">Save</button>
                </div>
            </div>
        </div>

    </div>
</div>
<jw-modal id="add-watermark" class="doc-del-pages alert-box">
    <div class="alert-box ">
        <div class="close-x float-right" (click)="closeModal('add-watermark');">X</div>
        <div class="container">
            <div class="row" *ngIf="data">
                <div class="">
                    <div class="alertpara">
                        <p class="alertparatxt">Confirmation</p>
                    </div>
                    <div class="alertext bolText">Are you sure you want to add Watermark to Approved 
                        <!-- <b>{{data.name}}</b>  -->
                        document?</div>
                    <div class="alertbutton">
                        <button class="btn btn-default  alertbtn alertbtnno"
                            (click)="closeModal('add-watermark');">No</button>
                        <button class="btn btn-default alertbtn alertbtnyes"
                            (click)="postWatermark();closeModal('add-watermark');">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<jw-modal id="doc-watermark-success" class="doc-watermark-succes  alert-box">
    <div class="doc-iframe-scs-body ">

        <div class="container">
            <div class="row" *ngIf="data">
                <div class="alertbox">
                    <div class="close-x float-right" (click)="closeModal('doc-watermark-success');">X</div>
                    <div class="alertpara">
                        <p class="alertparatxt"> Success</p>
                    </div>
                    <div class="alertext">Congratulations!! You have successfully added Watermark to 
                        <!-- {{data.name}} -->
                        the document.
                    </div>
                    <div class="center">
                        <button class="btn btn-primary okbtn" (click)="closeModal('doc-watermark-success');cancel()">OK</button>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</jw-modal>
