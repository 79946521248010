
<!--  Corporate Internal Matters  -->

<div class="row main  stepitem ">
    <div class="row view-matter">
      <div class="col-12">
        <div class="row">
          <!-- client code  -->
          <div class="col-6">
            <div class="case_title">Matter Details - {{data?.title}}</div>
            <ul class="nav nav-pills mb-3 btnaction float-left " id="pills-tab" role="tablist">
              <li class="nav-item">
                <button id="High" name="priority" class="selectprior optionhigh active" id="pills-home-tab"
                  data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                  aria-selected="true" (click)="onFeatureClick('Timeline')">Timeline</button>
              </li>
              <li class="nav-item">
                <button id="Medium" name="priority" class="selectprior optionaling" id="pills-profile-tab"
                  data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab"
                  aria-controls="pills-profile" aria-selected="false"
                  (click)="onFeatureClick('Document');">Document(s)</button>
              </li>
              <li class="nav-item" *ngIf="product != 'corporate' && data?.type == 'internal'">
                <button id="Low" name="priority" class=" selectprior optionlow" id="pills-contact-tab"
                  data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab"
                  aria-controls="pills-contact" aria-selected="false" (click)="onFeatureClick('T&C')">Team Member(s) and
                  Client(s)</button>
              </li>
              <li class="nav-item" *ngIf="product == 'corporate' && data?.type == 'internal'">
                <button id="Low" name="priority" class=" selectprior optionlow" id="pills-contact-tab"
                  data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab"
                  aria-controls="pills-contact" aria-selected="false" (click)="onFeatureClick('T&C')">Team Member(s) and
                  External Counsel(s)</button>
              </li>
            </ul>
  
          </div>
          <div class="col-6" *ngIf="featureName == 'Document'">
            <div class="form-group float-right">
              <div class="input-group court" id="search">
                <input type="text" class="form-control textbox" placeholder="Search Documents" [(ngModel)]="searchText" (keyup)="keyup('doc')" />
                <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
              </div>
            </div>
  
          </div>
  
        </div>
  
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6">
      <div class="row" *ngIf="featureName == 'T&C'">
        <!-- client code  -->
        <div class="col-6">
          <ul class="nav nav-pills mb-3 btnaction float-left " id="pills-tab" role="tablist">
            <li class="nav-item">
              <button id="High" name="priority" class="selectprior optionhigh active" id="pills-home-tab" [ngClass]="{'active':selectedVal=='TM'}"
                data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                aria-selected="true" (click)="onClick('TM');onFeatureClick('T&C');">Team Member(s)</button>
            </li>
  
            <li class="nav-item" *ngIf="product != 'corporate'">
              <button id="Medium" name="priority" class="selectprior optionaling" id="pills-profile-tab" [ngClass]="{'active':selectedVal=='Clients'}"
                data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab"
                aria-controls="pills-profile" aria-selected="false" (click)="onClick('Clients');onFeatureClick('T&C');">Client(s)</button>
            </li>
            <li class="nav-item" *ngIf="product == 'corporate'">
              <button id="Medium" name="priority" class="selectprior optionaling" id="pills-profile-tab" [ngClass]="{'active':selectedVal=='Clients'}"
                data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab"
                aria-controls="pills-profile" aria-selected="false" (click)="onClick('Clients');onFeatureClick('T&C');">External Counsel(s)</button>
            </li>
          </ul>
          <!-- <ul>
            <li class="nav-item">
              <button id="Medium" name="priority" class="selectprior optionaling active" id="pills-profile-tab"
                data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab"
                aria-controls="pills-profile" aria-selected="true">
                <span *ngIf="selectedVal=='TM'">Add Team Member</span>
                <span *ngIf="selectedVal=='Clients' && product != 'corporate'">Add Client</span>
                <span *ngIf="selectedVal=='Clients' && product == 'corporate'">Add External Counsel</span>
              </button>
            </li>
          </ul> -->
        </div>
        <div *ngIf="selectedVal == 'TM'">
          <div *ngFor="let member of selectedMembers;let i=index;">
            <div class="form-group msgicofile">
              <div class="input-group court" id="court">
                <p class="form-control textbox msgitemcls">
                  <img *ngIf="i == 0" src="assets/img/tree.svg" class="notfiy_tree">
                  <label class="notify_mdg"> {{member.name}}</label>
                 <span style="float: right;">
                   <img src="assets/img/mailnotif.svg" class="msgnotify1 mailicocls" (click)="onMailClick()">
                   <img src="assets/img/msgico.svg" class="msgnotify1 msgicocls" (click)="onMessageClickTM()">
                   <img src="assets/img/delete.svg" *ngIf="i != 0" (click)="removeTeammember(member)" class="msgnotify1 delicocls ">
                </span>
                </p>
              </div>
            </div>
          </div>
          <div class="dashedborder">
            <div class="row" style="margin-top: 15px;">
              <h6 class="col-6 assign">Assign Team Member(s)</h6>
              <div class="col-6 selOn">
                <label class="callselect">Select All</label>&nbsp;
                <input type="checkbox" value="" id="selectAllMembers" (change)="selectAllMembers($event)">
              </div>
            </div>
            <!-- <div class="col-4" style="margin-top: 15px;">
              <h6>Assign Team Member(s)</h6>
            </div>
            <div class="col-4">
              <div class="form-group" id="selectbx">
                <div class="input-group court" id="search">
                  <div class="multicheck form-control textbox">
                    <label class="callselect">Select All</label>
                    <input class="form-check-inpu calimg calitem input-group-append checkboxitem" type="checkbox" value=""
                      id="selectAllMembers" (change)="selectAllMembers($event)">
                  </div>
                </div>
              </div>
            </div> -->
            <div class="form-group">
              <div class="input-group group-custom-align court" id="search">
                <input type="text" class="form-control textbox" placeholder="Search Team Member"
                  [(ngModel)]="searchTextMembers" (keyup)="keyup('tm')" />
                <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
              </div>
            </div>
            <div class="form-group" id="selectbx ">
              <div class="input-group group-custom-align court" id="search"
                *ngFor="let teammember of teammembersList |filter:searchTextMembers" >
                <div class="multicheck form-control textbox">
                  <div class="callselect" [innerHtml]="teammember.name"></div>
                  <input class="form-check-inpu calimg calitem input-group-app end checkboxitem" #inputE1 type="checkbox" id="flexCheckDefault"
                    (change)="selectTeammember(teammember)">
                </div>
              </div>
            </div>
            <div class="btnbox">
              <button type="reset" class="btn btn-default btncancel btnrightitem" (click)="cancelItems()">Cancel</button>
              <button type="submit" class="btn btn-primary btnsave savecls" (click)="saveItems('members')"
                [disabled]="isSaveEnableTM">Save</button>
            </div>
          </div>
        </div>
        <div *ngIf="selectedVal == 'Clients'">
          <div *ngFor="let member of selectedClients;let i=index;">
            <div class="form-group msgicofile">
              <div class="input-group court" id="court">
                <p class="form-control textbox msgitemcls">
                  <label class="notify_mdg"> {{member.name}}</label>
                  <span style="float: right;">
                  <img src="assets/img/mailnotif.svg" class="msgnotify1 mailicocls" (click)="onMailClick()">
                  <img src="assets/img/msgico.svg" class="msgnotify1  msgicocls" (click)="onMessageClick()">
                  <img src="assets/img/delete.svg" *ngIf="i != 0" class="msgnotify1 delicocls" (click)="removeClient(member)">
                </span>
                </p>
              </div>
            </div>
          </div>
  
          <div *ngFor="let member of selectedCorp;let i=index;">
            <div class="form-group msgicofile">
              <div class="input-group court" id="court">
                <p class="form-control textbox msgitemcls">
                  <label class="notify_mdg"> {{member.name}}</label>
                  <span style="float: right;">
                  <img src="assets/img/msgico.svg" class="msgnotify1 msgicocls" (click)="onMessageClick()">
                  <!-- <img src="assets/img/delete.svg" class="msgnotify1 delicocls " *ngIf="selectedCorp.length != 1" (click)="removeClient(member)"> -->
                </span>
                </p>
              </div>
            </div>
          </div>
          
          <div class="dashedborder">
            <!-- <div class="col-4" style="margin-top: 15px;">
              <h6 *ngIf="product != 'corporate'">Assign Client(s)</h6>
              <h6 *ngIf="product == 'corporate'">Assign External Counsel(s)</h6>
            </div>
            <div class="col-2">
              <div class="form-group" id="selectbx">
                <div class="input-group court" id="search">
                  <div class="multicheck form-control textbox">
                    <label class="callselect">SelectAll</label>
                    <input class="form-check-inpu calimg calitem input-group-append checkboxitem" type="checkbox" value=""
                      id="selectAllClients" (change)="selectAllClients($event)">
                  </div>
                </div>
              </div>
            </div> -->
            <div class="row" style="margin-top: 15px;">
              <h6 class="col-6 assign" *ngIf="product != 'corporate'">Assign Client(s)</h6>
              <h6 class="col-6 assign" *ngIf="product == 'corporate'">Assign External Counsel(s)</h6>
              <div class="col-6 selOn">
                <label class="callselect">Select All</label>&nbsp;
                <input type="checkbox" value="" id="selectAllClients" (change)="selectAllClients($event)">
              </div>
            </div>
            <div class="form-group">
              <div class="input-group group-custom-align court" id="search" *ngIf="product != 'corporate'">
                <input type="text" class="form-control textbox" placeholder="Search Client"
                  [(ngModel)]="searchTextClient" (keyup)="keyup('clnt')" />
                <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
              </div>
              <div class="input-group group-custom-align court" id="search" *ngIf="product == 'corporate'">
                <input type="text" class="form-control textbox" placeholder="Search External Counsels"
                  [(ngModel)]="searchTextClient" (keyup)="keyup('clnt')" />
                <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
              </div>
            </div>
            <div class="form-group " id="selectbx ">
              <div class="input-group group-custom-align court" id="search"
                *ngFor="let client of clientsList |filter:searchTextClient">
                <div class="multicheck form-control textbox">
                  <div class="callselect" [innerHtml]="client.name"></div>
                  <input class="form-check-inpu calimg calitem input-group-app
                                              end checkboxitem" #inputE2 type="checkbox" id="flexCheckDefault"
                    (change)="selectClient(client)">
                </div>
              </div>
            </div>
            <div class="btnbox">
              <button type="reset" class="btn btn-default btncancel btnrightitem" (click)="cancelItems()">Cancel</button>
              <button type="submit" class="btn btn-primary btnsave savecls" (click)="saveItems('clients')"
                [disabled]="isSaveEnableClient">Save</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row scrolly" *ngIf="featureName == 'Timeline'">
      
        <div class="wrapper">
            <ul class="StepProgress">
              <li class="StepProgress-item" *ngFor="let item of historyData">
               
                <div class="lgalitem">
                  <p class="pnumber pleagalsubitem padTop">{{item.title}}</p>
                  <p class="lgalsubitem">{{item.from_ts | date: 'MMMM dd, yyyy | h:mm a'}}</p>
                </div>
    
                <!--Notes section-->
    
                <div class="row" *ngIf="item.id != '' ">
                  
                <div id="parent">
                   <div id="wide">
                  <!-- Indication message for !notes -->
                    <div  *ngIf="!item.AddDesc && !item.notes">
                      <p> <strong class="indication mov">No notes added in this section.</strong></p>
                    </div>
                  </div>
                  <!--Add, Edit Main Notes Action-->
                  <div id="narrow">
                    <!-- <img src="assets/img/addnotes.svg" class="col-1 addIcon" *ngIf="!item.notes" matTooltip="Click To Add Notes"
                      (click)="notes = item.notes;item.AddDesc=!item.AddDesc;submitted = false;" [ngClass]="item.AddDesc? 'onActive' : '' "> -->
                      <button class="plusBtn" (click)="onAdd();notes = item.notes; item.AddDesc = !item.AddDesc; submitted = false;" [disabled]="isButtonClicked">
                        <img src="assets/img/addnotes.svg" class="col-1 addIcon" *ngIf="!item.notes" 
                         matTooltip="Click To Add Notes" [ngClass]="item.AddDesc ? 'onActive' : ''">
                      </button>
                  </div>
                  <!--Add, Edit Main Notes Action-->
                </div>
    
                  <!-- Main notes View -->
                  <div class="contDiv">
                    <!--Add, Edit Textbox-->
                    <div class="c-align c-dec" *ngIf="item.AddDesc || item.EditDesc && item.id != ''">
                      <form [formGroup]="form">
                        <textarea class="row container tex" formControlName="notes" [(ngModel)]="notes" rows="3" cols="50" maxlength="150" oninput="this.value = this.value.replace(/[^A-Za-z0-9-_/#&+()@!$%*|+=?`~<>,. ]|^ /g,'')"></textarea>
                        <div class="count-text">{{ form.get('notes')?.value?.length || 0 }}/150</div>
                        <div *ngIf="form.get('notes').errors && submitted" class="errorStyle">
                          <div *ngIf="form.get('notes').errors.required">Note is required</div>
                        </div>

                        <div class="" style="text-align:end">
                          <button type="reset" class="btn btn-default btncancel"
                            (click)="item.EditDesc=false; item.AddDesc=false; notes = '';isButtonClicked=false;">Cancel</button>
                          <button type="submit" class="btn btn-default btnsave"
                            (click)="item.EditDesc=false; item.AddDesc=false; item.notes = notes;addNotes(item);isButtonClicked=false;"
                            [ngClass]="{ 'disableMode': !form.valid || !form.dirty }"
                            [attr.disabled]="!form.valid || !form.dirty ? true : null">Save</button>
                        </div>
                      </form>
                    </div>
                    <!--Add, Edit Textbox-->
  
  
  
                    <div class="row container colab" *ngIf="!item.EditDesc && item.notes">
  
                    <p class="lgalsubitem pad2 textNotes" [ngClass]="!item.isNotesElipses ? 'noteselipses' : ''">{{item?.notes}}</p>
  
                    <a (click)="toggleNotesEllipsis(item)"><!--*ngIf="item.notes"-->
                      <u class="readMore"><strong>{{!item.isNotesElipses ? 'Read More' : 'Read Less'}}</strong></u>
                    </a>
  
                    <div class="btnMove">
                      <!-- <img src="assets/img/editico.svg" class="btnMenu editoo" id="btnMove" *ngIf="item.id != ''" matTooltip="Click To Edit Notes"
                      (click)="notes = item.notes;item.EditDesc=!item.EditDesc;submitted = false;" [ngClass]="item.EditDesc? 'onActive' : ''"> -->
                      <button class="editBtn" (click)="onAdd();notes = item.notes;item.EditDesc=!item.EditDesc;submitted = false;" [disabled]="isButtonClicked">
                        <img src="assets/img/editico.svg" class="btnMenu editoo" id="btnMove" *ngIf="item.id != ''"
                         matTooltip="Click To Edit Notes" [ngClass]="item.EditDesc ? 'onActive' : ''">
                      </button>
                    </div>
  
                    </div>
  
                  </div>
                  <!-- Main notes View -->
                    
                </div>
    
                <!--Notes section-->
              </li>
            </ul>
          </div>
          
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6" *ngIf="featureName != 'Document'">
      <!-- component right side -->
      <div class="row containerbox">
        <div class="col-sm-12">
          <div class="container">
            <div class="row inforowaling">
              <!-- arrow toggel begin-->
              <div class="col-sm-1 arrowcolumn" [ngClass]="isVisibleInfo? 'poschng' : '' ">
                <div class="infocontainerleft">
                  <p class="arrowtoggel" id="btntgl" (click)="isVisibleInfo=!isVisibleInfo">
                    <img src="assets/img/arrowdown.svg" class="slidearrowtgl" >
                  </p>
                </div>
              </div>
              <!-- arrow toggel end -->
              <!-- form summary compoenent -->
              <div *ngIf="!isVisibleInfo" class="col-sm-11 infoconalign">
                <div class="infocontainer" id="formtglfiv">
                  <div class="row summarycontainer">
                    <div class="col-sm-6">
                      <p class="psize">Summary</p>
                    </div>
                    <!-- <div class="col-sm-6">
                  <div class="hearingimg">
                    <img src="img/formcalendor.svg" class="formcal">
                  </div>
                  <div class="itemhearing">
                    <p class="hearing">Hearing </p>
                    <p class="hearingdate">Mar 30 2022</p>
                  </div>
                </div> -->
                  </div>
                  <!-- for edit form 1 castitle -->
                  <div class="form-group">
                    <div class="row summarycontainer">
                      <div class="col-sm-6">
                        <label for="title" class="editcase">Case Title <span class="mandate">*</span>
                        </label>
                        <div class='editable' id='txt1'>{{data.title}}</div>
                      </div>
                      <div class="col-sm-6" *ngIf="data?.type == 'internal'">
                        <input type='button' class="btn btn-primary float-right" id='but_enable' value='Edit'
                        (click)="matterInfoEdit()">
                      </div>
                    </div>
                  </div>
                  <!-- for edit summary case number -->
                  <div class="form-group summarycitem">
                    <label for="title" class="editcase">Matter Number <span class="mandate">*</span>
                    </label>
                    <div class='editable cnumber'>{{data.matterNumber}}</div>
                  </div>
                  <!-- for edit summary case type -->
                  <div class="form-group summarycitem">
                    <label for="title" class="editcase">Matter Type </label>
                    <div class='editable cnumber'>{{data.matterType}}</div>
                  </div>
                  <!-- for edit summary date of filling-->
                  <div class="form-group summarycitem">
                    <label for="title" class="editcase">Start Date</label>
                    <div class='editable cnumber'>{{data.startdate}}</div>
                  </div>
                  <div class="form-group summarycitem">
                    <label for="title" class="editcase">End Date</label>
                    <div class='editable cnumber'>{{data.closedate}}</div>
                  </div>
                  <!-- for edit summary Description-->
                  <div class="form-group summarycitem">
                    <label for="title" class="editcase">Description </label>
                    <div class='editable cnumber'>{{data.description}}</div>
                  </div>
                  <!-- for edit summary matter-->
                  <div class="form-group summarycitem">
                    <label for="title" class="editcase">Matter Tags </label>
                    <div class='editable cnumber'></div>
                  </div>
                  <!-- for edit summary status-->
                  <div class="form-group Judges summarycitem">
                    <div class="statusprior">
                      <label for="title" class="editcase">Status </label>
                      <div class='editable cnumber'>{{data.status}}</div>
                    </div>
                    <div class="statusprior">
                      <label for="title" class="editcase">Priority </label>
                      <div class='editable cnumber'>{{data.priority}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- form summary compoenent ends -->
            </div>
          </div>
          <!-- ends here -->
        </div>
      </div>
    </div>
    <div class="row " *ngIf="featureName == 'Document'">
      <div class="col-6">
        <ul class="nav nav-pills mb-3 btnaction float-left " id="pills-tab" role="tablist">
          <li class="nav-item" *ngIf="data?.type == 'internal'">
            <button id="High" name="priority" class="selectprior optionhigh" id="pills-home-tab" data-bs-toggle="pill"
              data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="false"
              [ngClass]="isNewDocument? 'active' : '' "
              (click)="onFeatureClick('Document');isNewDocument= !isNewDocument;AddExistingSelected = true;UploadDocSelected=false;">Add New +</button>
          </li>
        </ul>
      </div>
      <div class="col-6"></div>
    </div>
    <div class="row" *ngIf="featureName == 'Document'">
      <div class="col-6" *ngIf="isNewDocument">
        <h5 class="cname">Attach Document(s)</h5>
        <ul class="nav nav-pills mb-3 btnaction float-left " id="pills-tab" role="tablist">
          <li class="nav-item">
            <button id="High" name="priority" class="selectprior optionhigh active" id="pills-home-tab" [ngClass]="{'active':AddExistingSelected}"
              data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
              aria-selected="true" (click)="AddExistingSelected=true;UploadDocSelected=false">Add Existing</button>
          </li>
          <li class="nav-item" *ngIf ="product !='corporate'">
            <button id="Medium" name="priority" class="selectprior optionaling" id="pills-profile-tab" [ngClass]="{'active':UploadDocSelected}"
              data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
              aria-selected="false" (click)="UploadDocSelected=true;AddExistingSelected=false">Upload New</button>
          </li>
        </ul>
      </div>
      <div class="compswitch" *ngIf="isNewDocument && AddExistingSelected">
        <div class="row">
          <div class="col-5">
            <h6 class="cname" style="float: left;">Selected Document(s)</h6>
            <div>
              <div class="row" *ngFor="let document of selectedDocuments">
                <div class="col-6 docname">{{document.name}}</div>
                <div class="col-6 docremove"><i class="fa fa-regular fa-circle-xmark calimg input-group-append sgroup" aria-hidden="true"
                  (click)="removeDocument(document)"></i></div>
              </div>
            </div>
          </div>
          <div class="col-1"></div>
  
          <div class="col-6" style="margin-top: -90px;">

            <div class="form-group float-right">
              <div class="input-group court grpo" id="search">
                <input type="text" class="form-control textbox" placeholder="Search" [(ngModel)]="searchText" (keyup)="keyup('doc')" />
                <img src="assets/img/search.svg" class="calimg calitemo input-group-append searchimg">
              </div>
            </div>

            <div class="col-3 uplo">
              <h6 class="cname">Uploaded Document(s)</h6>
            </div>

            <div class="col-3" style="float: right">
              <div class="form-group" id="selectbx">
                <div class="input-group court" id="search" style="margin-top: -48px;margin-left: 45px;">
                  <div class="">
                    <label class="callselect selAll">Select All</label>&nbsp;
                    <input class="form-check-inpu calimg input-group-append checkboxitem" type="checkbox" value=""
                      id="selectAll" (change)="selectAll($event)">
                  </div>
                </div>
              </div>
            </div>
            <table class="table table-borderless tblgrid">
              <thead>
                <tr class="theader">
                  <th>
                    <p class="thheadname" style="color: #0B5A9A;">Name</p>
                  </th>
                  <th>
                    <p class="thheadname" style="color: #0B5A9A;">Description</p>
                  </th>
                  <th>
                    <p class="thheadname" style="color: #0B5A9A;">Tags</p>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="rone" *ngFor="let document of documentsList |filter:searchText">
                  <td class="tabcl">{{document.name}}</td>
                  <td class="tabcl">{{document.description}}</td>
                  <td class="tabcl" *ngIf="document?.tags && document.tags.length>0" [innerHTML]="document?.tags[0]"></td>
                  <td class="tabcl" *ngIf="!(document?.tags?.length>0)"></td>
                  <td class="tabcl"><input class="" type="checkbox" id="flexCheckDefault" (change)="selectDocument(document)"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btnbox">
            <button type="reset" class="btn btn-default btncancel btnrightitem"
              (click)="cancelDocuments()">Cancel</button>
            <button type="submit" class="btn btn-primary btnsave savecls" 
              (click)="saveDocuments()">Submit</button>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isNewDocument && UploadDocSelected">
        <div *ngIf="DragAndDropView">
        <div class="col-2"></div>
        <div class="col-8">
          <ngx-file-drop [dropZoneClassName] = "'customcss'" [contentClassName]="'customcss1'" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
            (onFileLeave)="fileLeave($event)" class="customcss1">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              Drag and Drop Documents to upload
              <button class="brwsebutton" type="button" (click)="openFileSelector()">Browse</button>
            </ng-template>
          </ngx-file-drop>
        </div>
        <div class="row" *ngIf="uploadedDocs.length>0">
          <div class="col-6" *ngFor="let item of uploadedDocs;let i=index;">
              <div #itemlist class="documents-list ng-item" > {{item.name}}
                <div class="icon-list">
                  <div class="icons"><i class="fa  fa-times-circle" style="color:red"
                      (click)="removeNewDocument(item,i)"></i>
                  </div>
                </div>
              </div>
          </div>
        </div>
          <div class="btnbox nextclass">
            <button type="reset" class="btn btn-default btncancel" (click)="onUploadCancel()">Cancel</button>
            <button type="submit" class="btn btn-default btnsave savecls" [ngClass]="{'disabled':uploadedDocs.length==0 }" (click)="DragAndDropView=false">Next</button>
          </div>
      </div>
      <div *ngIf="!DragAndDropView">
        <div class="col-12 main-width">
          <label for="title" style="color:#00498E; margin: 20px 0;" class="fontbold">Selected document(s)</label>
          <div class="row">
            <div class="col-6">
              <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
                <label class="btn btn-outline-primary" for="btnradio1" (click)="docEnable('enable')">Enable Download</label>
    
                <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
                <label class="btn btn-outline-primary" for="btnradio2" (click)="docEnable('disable')">Disable
                  Download</label>
              </div>
    
            </div>
            <div class="col-6 width-align-upload">
              <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="btnradio1" id="btnradio3" autocomplete="off">
                <label class="btn btn-outline-primary" for="btnradio3" (click)="filterDoc('editMeta')">Edit Metadeta</label>
    
                <input type="radio" class="btn-check" name="btnradio1" id="btnradio4" autocomplete="off">
                <label class="btn btn-outline-primary" for="btnradio4" (click)="filterDoc('addTags')">Add Tags</label>
              </div>
              <div *ngIf="!editMetaFlag" class="selectAll float-right"> Select All 
                <input class="inputEl" #inputEl
                  type="checkbox" (change)="checkAllItem(inputEl.checked)"></div>
            </div>
            <div class="codeAlign">
              <div class="cod" *ngFor="let item of uploadedDocs;let i = index">
    
                <div #itemlist class="documents-list ng-item" [ngClass]="{'active': selectedIdx == i}"> {{item.name}}
                  <div class="icon-list">
                    <div class="icons" *ngIf="editMetaFlag && editMetaData"> <i (click)="editDocument(item,i)"
                        class="fa fa-edit"></i></div>
                    <div class="icons" *ngIf="!editMetaFlag"><input type="checkbox" [checked]="item.checked"
                        (click)="checkedItem(item)"></div>
                    <div class="icons" [ngClass]="{'disable':!downloadDisabled }"><i class="fa fa-download"
                       ></i></div>
                    <div class="icons"><i class="fa  fa-times-circle" style="color:red" (click)="removeNewDocument(item,i)"></i>
                    </div>
                  </div>
                </div>
    
              </div>
    
            </div>
            <div *ngIf="editDoc">
              <hr class="new3">
              <form [formGroup]="documentDetail" (ngSubmit)="onSubmit()">
                <div class="col-12 ">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label for="documentName" class="fontbold">Document Name <span class="mandate">*</span>
                        </label>
                        <input type="text" class="form-control textbox"  id="documentName"
                          name="documentName" formControlName="name"
                          [ngClass]="{'is-invalid':submitted && f.name.errors}">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                          <div *ngIf="f.name.errors.required">Document Name is required</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="title" class="fontbold"> Expiration Date</label>
                        <mat-form-field appearance="fill" class="form-group">
                          <input matInput [matDatepicker]="disableTyping"    formControlName="date_of_filling" name="date_of_filling"
                            class="form-control "  readonly>
                            <mat-datepicker-toggle matSuffix [for]="disableTyping">
                            </mat-datepicker-toggle>
                            <mat-datepicker #disableTyping></mat-datepicker>
                        </mat-form-field>
                        
                      </div>
                      <div class="form-group">
                        <label for="filling date" class="fontbold">Description</label><!--<span class="mandate">*</span>-->
                        <textarea rows="4" cols="50" class="form-control textbox"
                          formControlName="description" name="description"></textarea>
                        <!-- 
                          [ngClass]="{'is-invalid':submitted && f.description.errors}" maxlength="300"
                          <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                          <div *ngIf="f.description.errors.required">description is requiredzz</div>
                        </div> -->
                        <div class="count-div">
                          <div class="count-text" *ngIf="f.description">{{f.description.value.length}}/300</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="btnbox">
                  <button type="button" class="btn btn-default btncancel" (click)="onReset()">Cancel</button>
                  <button type="submit" class="btn btn-default btnsave savecls">Save</button>
    
                </div>
              </form>
            </div>
            <div class="row metadata">
              <div class="col-3"></div>
              <div *ngIf="!editMetaFlag" class="col-6">
                <div class=" name-set">
                  <div class="tags">
                    <div class="tags-width float-right"><button class="btn btn-primary" (click)="addvalue()">ADD</button></div>
                  </div>
    
                  <div *ngFor="let value of values; let i = index">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-5">
                          <div class="form-group name-set">
                            <label for="title" class="fontbold tab-margin">Tag Type </label>
                            <input type="text" [(ngModel)]="value.tagtype" #name="ngModel" name="tags{{ i }}"
                              class="form-control textbox" id="caseType">
                          </div>
                        </div>
                        <div class="col-5">
                          <div class="form-group name-set">
                            <label for="title" class="fontbold tab-margin">Tags </label>
                            <input type="text" [(ngModel)]="value.tag" #name="ngModel" name="tags{{ i }}"
                              class="form-control textbox" id="caseType">
                          </div>
                        </div>
                        <div class="col-1">
                          <label for="title" class="fontbold tab-margin"> </label>
                          <div (click)="removevalue(i)"><i class="fa  fa-times-circle" aria-hidden="true"></i></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="btnbox">
                  <button type="reset" class="btn btn-default btncancel" (click)="onResetTags()">Cancel</button>
                  <button type="submit" class="btn btn-default btnsave" (click)="submit()">Save</button>
                </div>
              </div>
              <div class="col-3"></div>
            </div>
          </div>
    
        </div>
        <div class=" row main stepitem  ">
          <div class="col-sm-12" *ngIf="editMetaFlag && !editDoc">
            <!-- button cancel and save -->
            <div class="btnbox">
              <button type="reset" class="btn btn-default btncancel" (click)="onUploadCancel()">Cancel</button>
              <button type="submit" class="btn btn-default btnsave savecls uploadClass" 
                (click)="saveUploadedDocuments()">Save & Upload</button>
    
            </div>
          </div>
        </div>
      </div>
      </div>
      <div *ngIf="!isNewDocument">
        <!-- <button id="High" name="priority" class="selectprior optionhigh" id="pills-home-tab" data-bs-toggle="pill"
          data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="false"
          [ngClass]="isMergeEnable? 'active' : '' " (click)="isMergeEnable= !isMergeEnable;onMergeClick();">Merge</button> -->
        <br />
        <input *ngIf="isMergeEnable" type="checkbox" id="mergeSelectAll" #inputE3 class="larger" name="checkBox2" (change)="CheckAllOptions(inputE3.checked)">
        <table class="table table-borderless tblgrid">
          <thead>
            <!-- <tr class="theader">
              <th></th>
              <th>
                <p class="thheadname">Document Name</p>
                <img src="assets/img/SORT.PNG" class="sortico" (click)="sort('name',selectedDocuments)">
              </th>
              <th>
                <p class="thheadname">Added By</p>
                <img src="assets/img/SORT.PNG" class="sortico" (click)="sort('addedBy',selectedDocuments)">
              </th>
              <th>
                <p class="thheadname">Description</p>
                <img src="assets/img/SORT.PNG" class="sortico" (click)="sort('description',selectedDocuments)">
              </th>
              <th>
                <p class="thheadname">Tags</p>
                <img src="assets/img/SORT.PNG" class="sortico" (click)="sort('tags',selectedDocuments)">
              </th> 
              <th></th>
              <th></th>
            </tr> -->
            <tr class="theader">
              <th>
                <p class="thheadname">Document Name</p>
                <img src="assets/img/SORT.PNG" class="sortico" (click)="sort('name',selectedDocuments)">
              </th>
              <th>
                <p class="thheadname">Description</p>
                <img src="assets/img/SORT.PNG" class="sortico" (click)="sort('description',selectedDocuments)">
              </th>
              <th>
                <p class="thheadname">Added On</p>
                <img src="assets/img/SORT.PNG" class="sortico" (click)="sort('created',selectedDocuments)">
              </th>
              <th>
                <p class="thheadname">Added By</p>
                <img src="assets/img/SORT.PNG" class="sortico" (click)="sort('addedBy',selectedDocuments)">
              </th>
              <th>
                <p class="thheadname textport">View</p>
              </th>
              <th>
                <p class="thheadname textport">Remove</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="rone veiw-items" *ngFor="let doc of selectedDocuments |filter:searchText">
              <!-- <td *ngIf="isMergeEnable">
                <input type="checkbox" [(ngModel)]="doc.checked" #inputE4 class="larger" name="checkBox2"
                  (change)="selectMergeDocument(inputE4.checked,doc)">
              </td>
              <td *ngIf="!isMergeEnable">
              </td>
              <td>{{doc.name}}</td>
              <td *ngIf="doc.addedBy">{{doc.addedBy}}</td>
              <!-- <td *ngIf="doc.uploaded_by">{{doc.uploaded_by}}</td> --
              <td>{{doc.description}}</td>
              <td *ngIf="doc?.tags && doc.tags.length>0" [innerHTML]="doc?.tags[0]"></td>
              <td *ngIf="!doc?.tags"></td>
              <td>
                <button (click)="viewDocument(doc)"><img src="assets/img/eyesico.svg"  /></button>
                <!-- <img src="assets/img/eyesico.svg" (click)="viewDocument(doc)"> --
              </td>
              <td (click)="documentDelete(doc)" *ngIf="data?.type == 'internal'">
                <i class="fa fa-regular fa-circle-xmark calimg calitem input-group-append sgroup removedoc" aria-hidden="true"></i>
              </td> -->
              <td class="tabclass doco">{{doc.name}}</td>
              <td class="tabclass doco">{{doc.description}}</td>
              <td class="tabclass">{{doc.created | date: 'MMMM dd, yyyy'}}</td>
              <td class="tabclass">{{doc.addedBy}}</td>
              <td class="tabclass"><img (click)="viewDocument(doc)" src="assets/img/eyesico.svg"/></td>
              <td class="tabclass"><i (click)="documentDelete(doc)" *ngIf="data?.type == 'internal'" class="fa fa-regular fa-circle-xmark calimg calitem input-group-append sgroup removeddoc" aria-hidden="true"></i></td>
            </tr>
          </tbody>
        </table>
        <div class=" row main stepitem" *ngIf="isMergeEnable && selectedMergeDocuments?.length >=2">
          <div class="col-sm-12">
            <div class="btnbox">
              <button type="reset" class="btn btn-default btncancel" (click)="onMergeCancel()">Cancel</button>
              <button type="submit" class="btn btn-default btnsave savecls" (click)="mergeDoc()">Merge</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>