<!--component html goes here -->
<!--component html goes here -->
<div class="row view-document">
  <div class="col-12">
    <div class="row">
      <!-- client code  -->
      <!-- <div class="col-6"> -->
      <div class="col-6" *ngIf="filter==='client' && product != 'corporate'">
        <div class="form-group" >
          <label for="title" class="fontbold" *ngIf="product !== 'content'">Client Name <span class="mandate">*</span></label>
          <label for="title" class="fontbold" *ngIf="product === 'content'">Customer Name <span class="mandate">*</span></label>
          <div class="ng-autocomplete textbox">
            <ng-autocomplete class="ngauto" [data]="data" [searchKeyword]="keyword"  placeholder="Type to select"
              (selected)='selectEvent($event)' 
              (inputCleared)='onChangeSearch($event)'
              [initialValue]="selectedClient"
              (inputFocused)='onFocused($event)' 
              [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
              <!-- <i class="fa fa-chevron-down" aria-hidden="true"></i> -->
            </ng-autocomplete>
            <ng-template #itemTemplate let-item>
              <a [innerHTML]="item.name"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
            <!-- <i class="fa fa-chevron-down"></i> -->
          </div>
        </div>
        <div *ngIf="getClient?.length>0">
        <div class="form-group" *ngIf="product === 'lauditor' || product === 'corporate'">
          <label for="title" class="fontbold">Matter </label>
          <!-- <label for="title" class="fontbold" *ngIf="product == 'corporate'">Matter <span class="mandate">*</span></label> -->
          <div class="dropdown-select textbox">
            <select (change)="onChange($event.target)">
              <option hidden value="">Select matter</option>
              <option *ngFor="let i of matterList " value="{{i.id}}">{{i.title}}</option>
            </select>
            <!-- <i class="fa fa-chevron-down"></i> -->
          </div>
        </div></div>
      </div>
      <!-- firm code -->
     <div class="col-6" *ngIf="filter==='firm'">
        <div class="form-group" id="selectbx">
          <div class="input-group court" id="search">
            <p class="deptCls" *ngIf="product == 'corporate'">Department(s)<span class="mandate">*</span></p>
            <div class="multicheck form-control textbox">
              <label class="callselect" *ngIf="product != 'corporate'">Select Group(s) <span class="mandate">*</span></label>
              <label class="callselect" *ngIf="product === 'corporate'">Select Department(s)</label>
              <i *ngIf="!isSelectGroup"
                class="fa fa-plus form-check-inpu calimg calitem input-group-append checkboxitem moveRyt" aria-hidden="true" (click)="selectGroup(true)"></i>
              <i *ngIf="isSelectGroup"
                class="fa fa-minus form-check-inpu calimg calitem input-group-append checkboxitem moveRyt" aria-hidden="true" (click)="selectGroup(false)"></i>
            </div>
          </div>
          <div class="" *ngIf="isSelectGroup">
            <div class="input-group index court" id="search">
              <div class="multicheck form-control textbox" *ngFor="let item of groupViewItems">
                <label class="callselect">{{item.name}}</label>
                <input type="checkbox" class="calimg calitem
                input-group-append checkboxitem " 
                value=""  id="flexCheckDefault"  #inputEl  [checked]="item.isChecked" (change)="selectGroupItem(item,inputEl.checked)">
              </div>
            </div>
          </div>

          <div class="row">
            <div *ngIf="!isSelectGroup" class="codeAlign selected-group">
              <div class="cod" *ngFor="let item of selectedGroupItems;let i = index">
                <div class="documents-list ng-item"> {{item.name}}
                  <div class="icon-list">
                    <div class="icons"><i class="fa fa fa-regular fa-circle-xmark" style="color:red;font-size: 24px;"
                        (click)="removeGroup(item)"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="form-group" *ngIf="product != 'corporate'">
          <label for="title" class="fontbold">Categories </label>
          <input type="text" class="form-control textbox" placeholder="Type to select" id="caseType" 
          (input)="restrictSpaces($event)" oninput="this.value = this.value.replace(/[^A-Za-z0-9-_ ]|^ /g,'')" name="caseno">
        </div>
        
        <!-- <div class="form-group" *ngIf="product === 'corporate'">
          <label for="title" class="fontbold deptCls">Matters</label> &nbsp;&nbsp;
          <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            [(ngModel)]="selectedmatterType">
            <mat-radio-button color="primary" (change)="get_all_matters(matter.value,$event)" *ngFor="let matter of matter_type" [value]="matter.value">
              {{matter.title}}
            </mat-radio-button>
          </mat-radio-group> 
            <div class="dropdown-select textbox">
              <select class="textbox" (change)="onChange($event.target)">
                <option hidden value="">Select matter</option>
                <option class="textbox" *ngFor="let i of corp_matter_list "
                  value="{{i.id}}">{{i.title}}</option>
              </select>
              <i class="fa fa-chevron-down"></i>
            </div>     
        </div> -->

        <!-- <div  class="invalid-feedback" *ngIf="clientErr"> Please select client</div> -->
     </div>

      <div class="col-6">
        <div class="form-group float-right">
          <div class="input-group court" id="search">
            <input type="text" class="form-control textbox" placeholder="Search" (input)="restricttextSpace($event)" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]|^ /g,'')" [(ngModel)]="term"/>
            <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
          </div>
        </div>
        <!-- <div class="form-group float-right">
          <div class="input-group court" id="search">
            <input type="text" class="form-control textbox" disabled placeholder="Advanced Search " />
          <img src="assets/img/plus.svg" class="calimg calitem input-group-append searchimg">
          </div>
        </div> -->
   
      </div>

    </div>

  </div>
</div>
<div *ngIf="!errorMsg" class="margin-top">
  <!-- <div  *ngIf="filter=='client'" class="custom-alert">Please select client name </div>
  <div *ngIf="filter=='firm'"class="custom-alert">Please select group name </div> -->
  <div *ngIf="filter=='client' && documents && documents?.length==0" class="custom-alert">Please Select Client name </div>

  <div *ngIf="filter=='firm' && selectedGroupItems && selectedGroupItems.length==0 && product != 'corporate'" class="custom-alert">Please Select Group name </div>
  <div *ngIf="filter=='firm' && selectedGroupItems && selectedGroupItems.length==0 && product == 'corporate'" class="custom-alert">Please Select Department name </div>
</div>
<div   class="col-sm-12 gridtable  width-alignment">
  <h1  *ngIf="filter=='client' && product != 'corporate'" class="custusername margin-top">List of Client Documents</h1>
  <h1  *ngIf="filter=='firm' && product != 'corporate'" class="custusername margin-top">List of Firm Documents</h1>

  <h1  *ngIf="filter=='firm' && product == 'corporate' && selectedmatterType == 'internal'" class="custusername margin-top">List of Internal Documents</h1>
  <h1  *ngIf="filter=='firm' && product == 'corporate' && selectedmatterType != 'internal'" class="custusername margin-top">List of External Documents</h1>
  <div *ngIf="getClient?.length>0 || selectedGroupItems?.length>0">
  <div *ngIf="documents && documents?.length==0 && errorMsg" class="custom-alert">List of Documents not available!!</div>
    <table class="table table-borderless tblgrid">
    <tbody>
      <tr class="rone veiw-items"  [class.protected] = "document.is_encrypted" [ngClass]="{'active': document.isChecked, 'disabled-row': document.is_password}"
        *ngFor="let document of documents| filter:term; let i = index">
        <td style="text-decoration: underline;" class="document">{{document.name}}</td>
        <td class="document">{{document.description}}</td>        
        <td class="document">{{document.created | date:'MMM d, y'}}</td>
        <td class="document">{{document.uploaded_by}}</td>
        <!-- <td class="document">
         <div *ngIf="document?.tags!=0" [innerHTML]="document.tags"></div>   
        </td> -->
        <td class="document">
          <div *ngIf="document?.tags && objectKeys(document.tags).length > 0">
            <ul>
              <li *ngFor="let key of objectKeys(document.tags)">
                {{ key }}: {{ document?.tags[key] }}
              </li>
            </ul>
          </div>
        </td>

        <td class="document"><i class="fa fa-download" aria-hidden="true"></i></td>
        <td class="document" *ngIf="document.is_encrypted">
          <i class="fa fa-exclamation-circle" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="This document connot be merged"></i>
        </td>
        <td class="document" *ngIf="!document.is_encrypted">
          <input *ngIf="!document.is_password" type="checkbox" class="form-check-input custcheck cusCheckbox" id="check3" name="option1" value="something">
          <input  style="cursor:pointer" *ngIf="!document.is_password" type="checkbox" #inputEl class="larger" name="checkBox2" (change)="checkItem(inputEl.checked,document)" [checked]="document.isChecked">
          <!-- <i *ngIf="document.is_password" matTooltip="This document cannot be merged as it is encrypted / password protected." class="fa fa-info-circle" style="font-size: 35px;color:#85858593" aria-hidden="true"></i> -->
          <img *ngIf="document.is_password" src="assets/img/pwdinfo.svg" matTooltip="This document cannot be merged as it is encrypted / password protected." class="pwdinfo">
        </td>
      </tr>
    </tbody>
    </table>
  <div class="row main stepitem">
    <div class="col-sm-12">
      <!-- button cancel and save -->
      <div class="btnbox">
        <button type="reset" (click)="mergeCancel()" class="btn btn-default btncancel">Cancel</button>
        <!-- <button type="submit" class="btn btn-default btnsave savecls" [disabled]="selectedDoc && selectedDoc.length<2" (click)="mergeDoc()">Merge</button>  -->
        <button type="submit" class="btn btn-default btnsave savecls" [ngClass]="{'disabled': (selectedDoc && selectedDoc.length < 2) }" (click)="mergeDoc()">Merge</button>
      </div>
    </div>
  </div>
</div></div>