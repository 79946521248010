<!-- <div class="row width-alignment">
    <div class="col-12 width-alignment">
        <div class="paymenttitle margin-div">Payment Details</div>
        <div class="row  margin-div">
        </div>
    </div>
</div> -->

<div class="container main width-alignment">
    <div class="col-12 Individual createGroup">
        <div class="audit-head"> Payment Details </div>
        <p class="labelText">Payment Subscription Information - Active Account - Payment Due</p>

        <div class="row">
            <!-- <div class="col-6">
                            <div class="form-group ">
                                <label for="title" class="lable-size">PLAN NAME</label>
                            </div>
                  </div> -->

            <!-- <div class="container width-alignment">
                <div class="plan-head"> PLAN NAME </div>
            </div> -->

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-5">
                        <div class="col-sm-12 container">

                            <div class="plan-head"> PLAN NAME </div>

                                <div class="form-group">
                                    <label for="name" class="fontbold">Number of License: </label> 
                                    &nbsp;<b style="color:#004D87;">X</b>
                                </div>

                                <div class="form-group">
                                    <label for="name" class="fontbold">Storage: </label>
                                    &nbsp;<b style="color:#004D87;">0 GB</b>
                                </div>

                                <div class="form-group">
                                    <label for="name" class="fontbold">Subscription Start Date: </label>
                                    &nbsp;<b style="color:#004D87;">DD/MM/YYYY</b>
                                </div>

                                <div class="form-group">
                                    <label for="name" class="fontbold">Duration: </label>
                                    &nbsp;<b style="color:#004D87;">1 Month/Year</b>
                                </div>

                                <div class="form-group">
                                    <label for="name" class="fontbold">Bill Amount Paid: </label>
                                    &nbsp;<b style="color:#004D87;">$XXX</b>
                                </div>

                                <div class="form-group">
                                    <label for="name" class="fontbold">Status: </label> 
                                    &nbsp;<b style="color:#004D87;">Active</b>
                                </div>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2">
                    <!-- <button>Add</button> <br><br>
                    <button>Remove</button><br><br>
                    <button>Upgrade</button> -->
                    <button class="btn btn-primary actionbtn">Add/Remove</button><br><br>
                    <button class="btn btn-primary actionbtn">Upgrade</button>

                </div>

                <div class="col-xs-12 col-sm-12 col-md-5">
                    <div class="dInvoice">
                        <button class="btn btn-primary actionbtn">Download Invoice</button>
                    </div>
                    <div>
                        <p class="plan-head cen">Payment Due</p>
                        <p class="cen">MM, YYYY</p>
                        <p class="cen"> $XXX</p>
                        <p class="cen">Licenses: &nbsp;<b style="color:#004D87;">X</b></p>
                    </div>
                    <div class="cen">
                         <!-- <button>Pay Now</button> -->
                         <button class="btn btn-primary actionbtn">Pay Now</button>
                    </div>
                </div>

            </div>

            <!-- <div class="col-6">
                <div class="form-group nameSet">
                    <div class="input-group court" id="search">
                        <label for="title" class="lable-size"></label>
                        <input type="text" class="form-control textbox seogroup"
                            placeholder="Search">
                        <img style="top: 20px;" src="assets/img/search.svg"
                            class="calimg calitem input-group-append searchimg">
                    </div>
                </div>
                <div class="form-group nameSet">
                </div>
            </div> -->
        </div>

    </div>