<!--component html goes here -->
<div class="col-sm-12 compswitch ">
  <div class="row">
    <div class="col-sm-3 col-xs-1"></div>
    <div class="col-sm-2 col-xs-3 round-icon">
      <!-- <div>
        <p class="selectprior optionhigh2 casetito" [matTooltip]="data.Title" matTooltipPosition="after"> {{truncateString(data.Title)}} </p> 
        <!-- <p class="">{{data.Type}}</p> --
      </div> -->

      <div>
        <p class="selectprior optionhigh2 casetito" [matTooltip]="data.Title" matTooltipPosition="after"> {{truncateString(data.Title)}} </p> 
        <div class="flex-container" *ngIf="product === 'corporate'">
          <!-- <div class="selectpriorGrp optionhigh2 flexo" *ngFor="let item of groupName"> {{item}} </div>  -->
          <b class="selGroup">Selected Departments:</b>
          <div class="divShow" *ngFor="let item of (showAllItems ? groupName : groupName.slice(0, 2)); let i = index">
            {{ item }}
            <span *ngIf="groupName.length == 1"> </span>
            <span *ngIf="groupName.length > 1 && i < (showAllItems ? groupName.length : 2) - 1">, </span>
          </div>
          <div *ngIf="groupName.length > 2" class="view-more-less" (click)="toggleView()">
            <a href="javascript:void(0)">{{ showAllItems ? 'View Less' : 'View More' }}</a>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="row">
    <div class="col-3"></div>
    <div class="col-6">
      <!-- <ul class="nav nav-pills mb-3 btnaction float-left " id="pills-tab"
        role="tablist">
        <li class="nav-item" *ngIf="groups && groups.length>0">
          <button id="High" name="priority" class="selectprior optionhigh"
            id="pills-home-tab" data-bs-toggle="pill"
            data-bs-target="#pills-home" type="button" role="tab"
            aria-controls="pills-home"
            aria-selected="true">{{groups[0].name}}</button>
        </li>

        <li class="nav-item" *ngIf="groups && groups.length>1">
          <span>
            <button id="High" name="priority" class="selectprior optionhigh"
              id="pills-home-tab" data-bs-toggle="pill"
              data-bs-target="#pills-home" type="button" role="tab"
              aria-controls="pills-home" aria-selected="true"
              [matTooltip]="tooltipgroupslist">{{groups[1].name}}</button>
            <!-- <span *ngIf="displayMouseOver">{{tooltipgroupslist}}</span> --
          </span>
        </li>
      </ul> -->
    </div>
    <!--<div class="col-3"></div>-->
  </div>
  <div class="col-12 Individual createGroup">
    <div class="row">
      <div class="col-6 matterpadding">
        <!--<div *ngIf="selectedClients.length>0">-->
          <h5 *ngIf="product != 'corporate'" class="float-left grpFont">Selected Client(s)</h5>
          <h5 *ngIf="product == 'corporate'" class="float-left grpFont">Selected External Counsel(s)</h5>
          
          <div class="selectedone">
            <div class="form-group group-custom-align" *ngFor="let client of selectedClients">
              <div class="input-group multicheck court" id="selectedgroup">
                <div class="form-control textbox textAl" [innerHtml]="client.name"></div>
                <i class="fa fa-regular fa-circle-xmark calimg calitem
                  input-group-append sgroup" aria-hidden="true"
                  (click)="removeClient(client)"></i>
              </div>
            </div>
          </div>
        <!--</div>-->
        <div *ngIf="tempClients?.length>0">
          <h5>Temporary Client(s)</h5>
          <div>
            <div class="form-group group-custom-align" *ngFor="let client of
              tempClients">
              <div class="input-group multicheck court" id="selectedgroup">
                <div class="form-control textbox textAl" [innerHtml]="client.name"></div>
                <i class="fa fa-regular fa-circle-xmark calimg calitem
                  input-group-append sgroup" aria-hidden="true"
                  (click)="removeTempClient(client)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 matterpadding">
        <!--<div class="col-3">-->
          <h5 class="float-left grpFont" *ngIf="product !== 'corporate'">Add Client(s)<span class="mandate">*</span></h5> 
          <h5 *ngIf="product !== 'corporate'" class="float-right clientSel">
            <div class="" *ngIf="!corporateData" id="selectbx">
            <div class="selOn" *ngIf="isSelectAllVisible">
            <label class="callselect checkAll">Select All</label>&nbsp;
            <input type="checkbox" value="" [checked]="filteredData?.length > 0 && selectedClients.length === filteredData.length" id="selectAll" (change)="selectAll($event)">
            </div></div>
          </h5>

          <h5 class="float-left grpFont" *ngIf="product === 'corporate'">Add External Counsels</h5>
          <h5 *ngIf="product === 'corporate'" class="float-right externalSel">
            <div class="" id="selectbx">
            <div class="selOn exter" *ngIf="isSelectAllVisible">
            <label class="callselect checkAll">Select All</label>&nbsp;
            <input type="checkbox" value="" [checked]="filteredData?.length > 0 && selectedClients.length === filteredData.length" id="selectAll" (change)="selectAll($event)">
            </div></div>
          </h5>
        <!--</div>-->



        <div clas="form-group" *ngIf="product != 'corporate'">
          <ul class="nav nav-pills mb-3 btnaction float-left " id="pills-tab"
            role="tablist">
            <li class="nav-item">
              <button id="High" name="addclients" class="selectprior optionhigh" id="pills-home-tab"
                data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                aria-selected="true" [ngClass]="{'active': selectedprod === 'clients'}"
                (click)="getClient()">Add Clients</button>
            </li>

            <li class="nav-item">
              <span>
                <button id="High" name="corpclient" class="selectprior optionhigh" id="pills-home-tab"
                  data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab"
                  aria-controls="pills-home" aria-selected="true"
                  [ngClass]="{'active': selectedprod === 'corporate'}" 
                  (click)="getCorp()">Corporate Clients</button>
              </span>
            </li>
          </ul>
        </div>


        <!-- <div clas="form-group" *ngIf="product == 'corporate'">
          <ul class="nav nav-pills mb-3 btnaction float-left " id="pills-tab"
            role="tablist">
            <li class="nav-item">
              <span>
                <button id="High" name="corpclient" class="selectprior optionhigh" id="pills-home-tab"
                  data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab"
                  aria-controls="pills-home" aria-selected="true"
                  [ngClass]="{'active': selectedprodCorp === 'corporate'}" 
                  >External Counsels</button>
              </span>
            </li>
          </ul>
        </div> -->

          <div *ngIf="corporateData && product != 'corporate'">
              <div class="form-group">
                <label for="country" class="fontbold alignleft addCC"> Add Corporate Clients </label> <!-- <span class="mandate">*</span> -->
                <select class="form-select calendorselect" [(ngModel)]="selectedOption" required placeholder="d.ff">
                  <option hidden value="" disabled selected> Select Corporate Clients </option>
                  <option *ngFor="let client of corporateList" class="textbox" [ngValue]="client">{{client.name}}</option>
                </select>
              </div>
              
              <div class="space">
              <button class="btn btn-primary actionbtn pull-right corpBtn" [disabled]="selectedOption.length == 0" (click)="onClickCorp()">Add to Selected Clients</button>
              </div>
          </div>
        
        <!-- Client lists-->
        <!-- <div class="col-3" *ngIf="!corporateData">
          <div class="form-group" id="selectbx">
            <div class="input-group court" id="search">
              <div class="multicheck form-control textbox" *ngIf="isSelectAllVisible">
                <label class="callselect selAl">Select All</label>
                <input class="form-check-inpu calimg calitem input-group-append
                  checkboxitem" type="checkbox" value="" 
                  [checked]="filteredData?.length > 0 && selectedClients.length === filteredData.length"
                  id="selectAll" (change)="selectAll($event)">
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="" *ngIf="!corporateData" id="selectbx">
          <div id="search">
            <div class="selOn" *ngIf="isSelectAllVisible">
              <label class="callselect checkAll">Select All</label>&nbsp;
              <input type="checkbox" value=""
                [checked]="filteredData?.length > 0 && selectedClients.length === filteredData.length" id="selectAll"
                (change)="selectAll($event)">
            </div>
          </div>
        </div> -->

        <!-- Corp lists-->
        <!-- <div class="col-3" *ngIf="product == 'corporate' && !corporateData">
          <div class="form-group" id="selectbx">
            <div class="input-group court" id="search">
              <div class="multicheck form-control textbox" *ngIf="isSelectAllVisible">
                <label class="callselect">Select All</label>
                <input class="form-check-inpu calimg calitem input-group-append
                  checkboxitem" type="checkbox" value=""
                  id="selectAll" (change)="selectCorp($event)">
              </div>
            </div>
          </div>
        </div> -->
        <!--*ngIf="product != 'corporate'"-->
        
        <!-- Search Clients-->
        <div class="form-group" *ngIf="!corporateData"> <!--*ngIf="!corporateData"-->
          <div class="input-group group-custom-align court" id="search" *ngIf="product != 'corporate'">
            <input type="text" class="form-control textbox searchtextcr" placeholder="Search Client" [(ngModel)]="searchText"
              (keyup)="keyup()" #searchInput/>
            <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
          </div>

          <div class="input-group group-custom-align court" id="search" *ngIf="product == 'corporate'">
            <input type="text" class="form-control textbox searchtextcr" placeholder="Search External Counsel" [(ngModel)]="searchText"
              (keyup)="keyup()" />
            <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
          </div>
        </div>

        <!-- <div class="form-group" *ngIf="!corporateData && product == 'corporate'"> 
          <div class="input-group group-custom-align court" id="search">
            <input type="text" class="form-control textbox" placeholder="Search External Counsels" [(ngModel)]="searchText"
              (keyup)="keyup()" (ngModelChange)="onFilterValueChange()" />
            <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
          </div>
        </div> -->

        <!-- Clients lists-->
        <div class="form-group" id="selectbx"><!-- *ngIf="tempList && tempList.length>0 " -->
          <div class="input-group group-custom-align court matterpadding"
            id="search" *ngFor="let client of clientsList |filter:searchText">
            <div class="multicheck form-control textbox" *ngIf="!corporateData">
              <div class="callselect textAl" [innerHtml]="client.name"></div>
              <input class="form-check-inpu calimg calitem input-group-app
                end checkboxitem" #inputEl type="checkbox" id="flexCheckDefault"
                [checked]="selectedClients.includes(client)"
                (change)="selectClient(client, inputEl.checked)">
            </div>
          </div>
        </div>

        <!-- Corporate lists-->
        <!-- <div class="form-group" id="selectbx" *ngIf="tempList && tempList.length>0 && product == 'corporate'">
          <div class="input-group group-custom-align court matterpadding"
            id="search" *ngFor="let client of corporateList |filter:searchText">
            <div class="multicheck form-control textbox" *ngIf="!corporateData">
              <div class="callselect" [innerHtml]="client.name"></div>
              <input class="form-check-inpu calimg calitem input-group-app
                end checkboxitem" #inputEl type="checkbox" id="flexCheckDefault"
                (change)="selectCorporate(client, inputEl.checked)">
            </div>
          </div>
        </div> -->
        
        <div *ngIf="product != 'corporate' && !corporateData">
        <!-- <div *ngIf="!tempList && tempList.length==0 && !corporateData"> -->
          <div *ngIf="clientsList.length == 0 && tempList && tempList.length == 0 && !corporateData || showTempForm">
          <span class="warn"> {{searchText}} - not found.</span><br>
          <span class="warn">Please fill in the details below to add {{searchText}} as client</span>

          <div clas="form-group">
            <label for="clienttype" class="fontbold alignleft ctype">Client Type </label>
             <!--<ul class="nav nav-pills mb-3 btnaction float-left " id="pills-tab"
              role="tablist">
              <li class="nav-item">
                <button id="High" name="individual" class="selectprior
                  optionhigh" id="pills-home-tab"
                  data-bs-toggle="pill" data-bs-target="#pills-home"
                  type="button" role="tab" aria-controls="pills-home"
                  aria-selected="true" [ngClass]="{'active': selectedType ==='individual'}" 
                  (click)="getType($event,'individual')">Individual</button>
              </li>
              <li class="nav-item">
                <span>
                  <button id="High" name="entity" class="selectprior optionhigh"
                    id="pills-home-tab"
                    data-bs-toggle="pill" data-bs-target="#pills-home"
                    type="button" role="tab"
                    aria-controls="pills-home" aria-selected="true"
                    [ngClass]="{'active': selectedType === 'entity'}"
                    (click)="getType($event,'entity')">Entity</button>
                </span>
              </li>
            </ul> -->

            <ul class="nav nav-pills mb-3 btnaction float-left" id="pills-tab" role="tablist">
              <li class="nav-item">
                  <button id="High" name="individual" class="selectprior optionhigh" 
                      [ngClass]="{'active': selectedType === 'individual'}" 
                      (click)="getType($event, 'individual')">Individual</button>
              </li>
              <li class="nav-item">
                  <button id="High" name="entity" class="selectprior optionhigh" 
                      [ngClass]="{'active': selectedType === 'entity'}" 
                      (click)="getType($event, 'entity')">Entity</button>
              </li>
          </ul>
          </div>
          <div [ngSwitch]="ClientType">
            <div *ngSwitchCase="'individual'">
              <form [formGroup]="tempClient" (ngSubmit)="onindividualSubmit('temp')">
                <div class="form-group">
                  <label for="name" class="fontbold alignleft"> First Name <span class="mandate">*</span> </label>
                  <input formControlName="name" type="text" class="form-control
                    textbox" id="name" name="name" (input)="restricttextSpace($event)">
                  <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div class="warn" *ngIf="f.name.errors.required">First name is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="lastName" class="fontbold alignleft"> Last Name <span class="mandate">*</span> </label>
                  <input formControlName="lastName" type="text" (input)="restricttextSpace($event)"
                    class="form-control textbox" id="lastName" name="lastName">
                  <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div class="warn" *ngIf="f.lastName.errors.required">Last name is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="email" class="fontbold alignleft">Email Address<span  class="mandate">*</span>  </label>
                  <input formControlName="email" type="email" (input)="restricttextSpace($event)"
                    class="form-control textbox" id="email" name="email"
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div class="warn" *ngIf="f.email.errors.required">Email is required</div>
                    <div class="warn" *ngIf="!f.email.errors.required">Email is invalid</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="confirmemail" class="fontbold alignleft">Confirm Email Address<span class="mandate">*</span> </label>
                  <input formControlName="confirmemail" type="email"
                    class="form-control textbox" id="confirmemail"
                    name="confirmemail" (input)="restricttextSpace($event)"
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                  <div *ngIf="submitted && f.confirmemail.errors" class="invalid-feedback">
                    <div class="warn" *ngIf="f.confirmemail.errors.required">Confirm Email is required</div>
                    <div class="warn" *ngIf="!f.confirmemail.errors.required">Confirm Email is invalid</div>
                  </div>
                  <div *ngIf="submitted && tempClient.errors?.emailMismatch" class="invalid-feedback">
                    <div class="warn">Confirm Email does not match</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="country" class="fontbold alignleft"> Country <span class="mandate">*</span> </label>
                  <!-- <input formControlName="country" type="text"
                    class="form-control textbox" id="country" name="country"> -->
                    <select  class="form-control textbox" formControlName="country">
                      <option value="" hidden>Choose </option>
                      <option *ngFor="let city of csvContent" [ngValue]="city">{{city}}</option>
                    </select>
                  <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                    <div class="warn" *ngIf="f.country.errors.required">Country is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="phonenumber" class="fontbold alignleft">Phone Number </label>
                  <input formControlName="phonenumber" type="tel" (input)="restricttextSpace($event)"
                    class="form-control textbox" id="phonenumber"
                    name="phonenumber" minlength="10" maxlength="10">
                  <!-- <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                    <div class="warn" *ngIf="f.phonenumber.errors.required">Phone Number is required</div>
                    <div class="warn" *ngIf="!f.phonenumber.errors.required">Phone Number is invalid</div>
                  </div> -->
                </div>

                <div class="btnbox">
                  <button type="button" class="btn btn-default btncancel
                    btnrightitem" (click)="OnFormCancel()">Cancel</button>
                  <button type="submit" class="btn btn-primary btnsave savecls addTemp"
                  >Add as Temporary</button>
                  <!-- <button type="submit" class="btn btn-primary btnsave savecls" (click)="inputsubmit='invite'">Send Invite</button> -->
                </div>
              </form>

            </div>
            <div *ngSwitchCase="'entity'">
              <form [formGroup]="entityClient" (ngSubmit)="oneEntitySubmit('entity')">
                <div class="form-group">
                  <label for="fullname" class="fontbold alignleft"> Firm Name <span class="mandate">*</span> </label>
                  <input formControlName="fullname" type="text" (input)="restricttextSpace($event)" class="form-control textbox" id="fullname" name="fullname">
                  <div *ngIf="entitysubmitted && f1.fullname.errors" class="invalid-feedback">
                    <div class="warn" *ngIf="f1.fullname.errors.required">Firm Name is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="contact_person" class="fontbold alignleft">Contact Person<span class="mandate">*</span> </label>
                  <input formControlName="contact_person" type="text"
                    class="form-control textbox" id="contact_person"
                    name="contact_person" (input)="restricttextSpace($event)">
                  <div *ngIf="entitysubmitted && f1.contact_person.errors" class="invalid-feedback">
                    <div class="warn" *ngIf="f1.contact_person.errors.required">Contact Person is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="email" class="fontbold alignleft">Email Address<span class="mandate">*</span> </label>
                  <input formControlName="email" type="email" (input)="restricttextSpace($event)"
                    class="form-control textbox" id="email" name="email"
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                  <div *ngIf="entitysubmitted && f1.email.errors" class="invalid-feedback">
                    <div class="warn" *ngIf="f1.email.errors.required">Email is required</div>
                    <div class="warn" *ngIf="!f1.email.errors.required">Email is invalid</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="confirmemail" class="fontbold alignleft">Confirm Email Address<span class="mandate">*</span> </label>
                  <input formControlName="confirmemail" type="email"
                    class="form-control textbox" id="confirmemail"
                    name="confirmemail" (input)="restricttextSpace($event)"
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                  <div *ngIf="entitysubmitted && f1.confirmemail.errors" class="invalid-feedback">
                    <div class="warn" *ngIf="f1.confirmemail.errors.required">Confirm Email is required</div>
                    <div class="warn" *ngIf="!f1.confirmemail.errors.required">Confirm Email is invalid</div>
                  </div>
                  <div *ngIf="entitysubmitted && entityClient.errors?.emailMismatch" class="invalid-feedback">
                    <div class="warn">Confirm Email does not match</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="country" class="fontbold alignleft"> Country <span class="mandate">*</span>  </label>
                  <select  class="form-control textbox" formControlName="country">
                    <option value="" hidden>Choose </option>
                    <option *ngFor="let city of csvContent" [ngValue]="city">{{city}}</option>
                  </select>
                  <!-- <input formControlName="country" type="text"
                    class="form-control textbox" id="country" name="country"> -->
                  <div *ngIf="entitysubmitted && f1.country.errors" class="invalid-feedback">
                    <div class="warn" *ngIf="f1.country.errors.required">Country is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="phonenumber" class="fontbold alignleft">Phone Number </label>
                  <input formControlName="phonenumber" type="tel" (input)="restricttextSpace($event)"
                    class="form-control textbox" id="phonenumber"
                    name="phonenumber" minlength="10" maxlength="10">
                  <!-- <div *ngIf="entitysubmitted && f1.phonenumber.errors" class="invalid-feedback">
                    <div class="warn" *ngIf="f1.phonenumber.errors.required">Phone Number is required</div>
                    <div class="warn" *ngIf="!f1.phonenumber.errors.required">Phone Number is invalid</div>
                  </div> -->
                </div>

                <div class="btnbox">
                  <button type="button" class="btn btn-default btncancel
                    btnrightitem" (click)="OnFormCancel()">Cancel</button>
                  <button type="submit" class="btn btn-primary btnsave savecls addTemp" 
                  >Add as Temporary</button>
                  <!-- <button type="submit" class="btn btn-primary btnsave savecls" (click)="inputsubmit='invite'">Send Invite</button> -->
                </div>
              </form>
            </div>
          </div>
        </div>
        </div>
      </div>

      <div class="btnbox">
        <button type="reset" class="btn btn-default btncancel btnrightitem" (click)="OnCancel()">Cancel</button>
        <button *ngIf="product != 'corporate'" type="submit" class="btn btnsave savecls" 
          [disabled]="!((selectedClients.length > 0) || (tempClients?.length > 0))"
          (click)="saveClients()">Next</button>
        <button *ngIf="product == 'corporate'" type="submit" class="btn btn-primary btnsave savecls"
          (click)="saveClients()">Next</button>
      </div>
      <!--
          [disabled]="!((selectedClients.length > 0 && isSaveEnable) || (tempClients?.length > 0 && isSaveEnable))"
          [ngClass]="{'disabled': !((selectedClients.length > 0 && isSaveEnable) || (tempClients?.length > 0 && isSaveEnable))}"
      -->
    </div>
  </div>

</div>


<div *ngIf='successModel' class="modal" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true" style="display:block; margin-top:50px">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-body">
            <div class="iconclose" (click)="noResp('no');">X</div>
              <div class="alertpara">
                <p class="alertparatxt">Alert</p>
              </div>
              <div class="alertext">
                <p class="dataText">Data will not be saved.</p> 
                <p class="dataText">Do you want to proceed ?</p>
              </div>
              <div class="alertbutton">
                <button class="btn btn-default alertbtn alertbtnyes" (click)="noResp('no')">No</button>
                <button class="btn btn-default alertbtn alertbtnno" (click)="successResp('yes');toggleType()">Yes</button>
              </div>
          </div>
      </div>
  </div>
</div>

<div *ngIf='keyModel' class="modal" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true" style="display:block; margin-top:50px">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-body">
            <div class="iconclose" (click)="nokeyResp('no');">X</div>
              <div class="alertpara">
                <p class="alertparatxt">Alert</p>
              </div>
              <div class="alertext">
                <p class="dataText">Data will not be saved.</p> 
                <p class="dataText">Do you want to proceed ?</p>
              </div>
              <div class="alertbutton">
                <button class="btn btn-default alertbtn alertbtnyes" (click)="nokeyResp('no')">No</button>
                <button class="btn btn-default alertbtn alertbtnno" (click)="keyResp('yes')">Yes</button>
              </div>
          </div>
      </div>
  </div>
</div>
