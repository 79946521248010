<!--component html goes here -->
 <!-- table grid -->
 <div class="row  width-alignment" *ngIf="product != 'corporate' && product != 'connect'">
  <div class="col-sm-12 compswitch ">
    <div class="switch relationship-tabs cswitch">
      <span class="relSubTab individuals">
        <a routerLink="/relationship/view/individuals"
           [class.active]="activeTab == 'individuals'"
           (click)="tabReset('individuals')"
           class="optionone compone relation-sub-tabs">Individuals</a>
      </span>
      
      <span class="relSubTab business" >
        <a routerLink="/relationship/view/business"
           [class.active]="activeTab == 'business'"
           (click)="tabReset('business')"
           class="optiontwo relation-sub-tabs compotwoo compthree">Businesses</a>
      </span>

      <span class="relSubTab corporate" *ngIf="product == 'corporate' || product == 'lauditor'">
        <a routerLink="/relationship/view/corporate"
           [class.active]="activeTab == 'corporate'"
           (click)="tabReset('corporate')"
           class="optiontwo relation-sub-tabs compotwoo compthree">Corporate</a>
      </span>

      <span class="relSubTab individuals" *ngIf="product != 'content' && product != 'connect'">
        <a routerLink="/relationship/view/temporary"
           [class.active]="activeTab == 'temporary'"
           (click)="tabReset('temporary')"
           class="optionthree comptwo compotwoo relation-sub-tabs">Temporary Clients</a>
      </span>
    </div>

    <!--Only for Product Connect-->
   <!--<div *ngIf="product == 'connect'">
        <a routerLink="/relationship/view/business"
           [class.active]="activeTab == 'business'"
           (click)="tabReset('business')"></a>
    </div>-->

  </div>
</div>

<div class="row" *ngIf="product == 'corporate'">
  <div class="col-sm-12 compswitch">
    <div class="switch relationship-tabs cswitch">
      <span class="relSubTab corporate">
        <a href="javascript: void(0)" (click)="tabReset('corporate')"
           class="optionone compone relation-sub-tabs borderPix"
           [class.active]="activeTab == 'corporate'">External Counsels</a>
      </span>

      <!--<span class="relSubTab entity">
        <a href="javascript: void(0)"
           [class.active]="activeTab == 'entity'"
           class="optiontwo relation-sub-tabs compotwoo comptwo"
           routerLink="/relationship/add/entity">External</a>
      </span>-->
    </div>

  </div>
</div>

<router-outlet></router-outlet>

 <div class="row main" *ngIf="!showModifyForm && !showExchange">
    <div class="col-sm-12 gridtable width-alignment" >

    <!--Search box div..-->
    <div class="row" *ngIf="product != 'corporate'">
      <div class="col-sm-6 custusername tab-margin fontweight"> 
          View Relationships - {{activeTab | titlecase }} 
        </div>
        <div class="col-sm-6">
          <div class="form-group name-set float-right">
            <div class="input-group group-custom-align court" id="search">
              <input type="text"
                     class="form-control textbox"
                     placeholder="Search" 
                     oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]|^ /g,'')" 
                     [(ngModel)]="searchText"/>
              <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">

            </div>
          </div>
        </div>
    </div>
    <!--Search box div..-->

    <!--Corporate Relationship Search box..-->
    <div class="row d-flex justify-content-center" *ngIf="product == 'corporate'">
      <div class="col-sm-6">
        <div class="form-group name-set float-center">
          <div class="input-group group-custom-align court" id="search">
    
            <div class="input-group court" id="search">
              <input type="text" placeholder="Search" [(ngModel)]="searchText" matInput 
              oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]|^ /g,'')"
              class="form-control textbox" ng-pattern="/^[a-zA-Z]*$/">
              <button id="btnadd" type="submit" class="input-group-append appendadd">Search</button>
            </div>
    
          </div>
        </div>
      </div>
    </div>
    <!--Corporate Relationship Search box ends here..-->
    <!-- <div class="norel" *ngIf="relationshipList.length===0">There are no relationships under this category</div> -->
     <div>
      <table class="table table-borderless tblgrid">
        <thead>
          <tr class="theader">
            <th>
              <p class="thheadname">Name</p>
              <img src="assets/img/SORT.PNG" class="sortico" (click)="sortingFile('name')"> 
            </th>
            <th *ngIf="product != 'corporate'">
              <p class="thheadname">Type</p>
              <img src="assets/img/SORT.PNG" class="sortico" (click)="sortingFile('clientType')">
            </th>
            <th>
              <p class="thheadname">Created On</p>
              <img src="assets/img/SORT.PNG" class="sortico" (click)="sortingFile('created')">
            </th>

            <th *ngIf="activeTab != 'temporary'">
              <p class="thheadname">Status</p>
              <img src="assets/img/SORT.PNG" class="sortico" (click)="sortingFile('isAccepted')">
            </th>
            
            <th>
              <p class="thheadname" *ngIf="product != 'corporate'">Groups</p>
              <p class="thheadname" *ngIf="product == 'corporate'">Departments</p>
              <img src="assets/img/SORT.PNG" class="sortico" (click)="sortingFile('groups')">
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr class="rone veiw-items" style="border: none;"
              *ngFor="let rel of relationshipList | filter:searchText"
              [class.active]="highlight == rel.id">
            <td class="tabclass">{{rel.name}}</td>
            <td class="tabclass" *ngIf="product != 'corporate'">{{rel.clientType}}</td>
            <td class="tabclass" *ngIf="rel.created">{{rel.created}}</td>
            <td class="tabclass" *ngIf="rel.created_on">{{rel.created_on}}</td>
            <!--<td *ngIf="activeTab == 'temporary'">{{rel.created_by}}</td>-->
           
            <td class="tabclass" *ngIf="activeTab != 'temporary'">
              <div *ngIf="!rel.canAccept">
                <div *ngIf="rel.isAccepted"> Active </div>
                <div *ngIf="!rel.isAccepted"> Pending</div>
              </div>
              <button class="btn btn-primary"
                      (click)="accept(rel)"
                      [ngClass]="{'disabled': rel.isDisabled}"
                      *ngIf=rel.canAccept>Accept</button>
            </td>
            <td class="tabclass">
                <ul>
                  <li *ngFor='let g of rel.filteredGroups'>{{g.name}}</li>
                </ul>
                <!-- <ul>
                  <li *ngFor='let g of rel.groups'>{{g.name}}</li>
                </ul> -->
            </td>
            <td class="tabclass">
              <div class="dropdown">
                <!-- Product based conditions for ActionBtn-->
                <button type="button" *ngIf="role === 'SU' && activeTab !== 'temporary' && (product === 'lauditor' || product === 'corporate')"
                class="btn btn-primary dropdown-toggle actionbtn" data-bs-toggle="dropdown"
                [ngClass]="{'disabled': rel.isDisabled || rel.isdisabled}"> Action </button>

                <button type="button" *ngIf="(role === 'TM' || role === 'GH') && rel.isAccepted && (product === 'lauditor')"
                class="btn btn-primary dropdown-toggle actionbtn" data-bs-toggle="dropdown"
                [ngClass]="{'disabled': rel.isDisabled || rel.isdisabled}"> Action </button>
                <button type="button" *ngIf="product === 'content' || product === 'connect'"
                class="btn btn-primary dropdown-toggle actionbtn" data-bs-toggle="dropdown"
                [ngClass]="{'disabled': rel.isDisabled || rel.isdisabled}"> Action </button>
                <button type="button" *ngIf="(role === 'TM' || role === 'GH') && product === 'corporate'"
                class="btn btn-primary dropdown-toggle actionbtn" data-bs-toggle="dropdown"
                [ngClass]="{'disabled': rel.isDisabled || rel.isdisabled}"> Action </button>
                 <!-- Product based conditions for ActionBtn-->
                
               <button type="button" *ngIf="activeTab === 'temporary'"
                class="btn btn-primary dropdown-toggle actionbtn" data-bs-toggle="dropdown"
                [ngClass]="{'disabled': rel.isDisabled || rel.isdisabled}"> Action </button>
                        
                <ul class="dropdown-menu">
                  <li *ngIf='rel.isAccepted'>
                    <a class="dropdown-item" (click)="exchangeInfo(rel)">Exchange Information</a>
                  </li>
                    <li *ngIf="(role == 'SU' && product == 'lauditor') || (product != 'lauditor')">
                    <a class="dropdown-item" (click)="modifyGroups(rel); scrollToTop()" *ngIf="product != 'corporate'"
                        href="javascript:void(0);">Modify Group Access</a>
                    <a class="dropdown-item" (click)="modifyGroups(rel)" *ngIf="product == 'corporate'"
                        href="javascript:void(0);">Update Department Access</a>
                  </li>
                  <li *ngIf="activeTab == 'temporary'">
                    <a class="dropdown-item" (click)="openConfirmBox(rel)" 
                        href="javascript:void(0);">Send Invite</a>
                  </li>
                  <!-- <li>
                    <a class="dropdown-item" (click)="delrel(rel)"
                        href="javascript:void(0);">Delete Relationship</a>
                  </li> -->
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
     </div>
    </div>
  </div>


  <app-relationship-group-access *ngIf='showModifyForm' [activeTab]="activeTab"
                                 [reldata]='relationshipData' [showModifyForm]="showModifyForm"
                                 (event)='onChildEvent($event);onCloseEvent()'></app-relationship-group-access>
  <app-relationship-exchange *ngIf='showExchange' [activeTab]="activeTab"
                             [reldata]='relationshipData'
                             (event)='onChildEvent($event)'></app-relationship-exchange>
<div class="modal"
     *ngIf='showDelConfirm'
     style="display:block;" 
     tabindex="-1">
  <div class="modal-dialog">
      <div class="modal-content">
              <button type="button"
                      class="btn-close"
                      data-bs-dismiss="modal" aria-label="Close"></button>
          <div class="modal-body">
              <div class="alertpara">
                  <p class="alertparatxt">Confirm</p>
                </div>
          </div>
          <div class="alertext" style="height:130px;">
            You will no longer be able to communicate and
            exchange information with <strong>{{selectedRel.name}}</strong>.
            Are you sure you want to end this digital relationship?
          </div>
          <div class="alertbutton">
              <button class="btn btn-default  alertbtn alertbtnno"
                      data-bs-dismiss="modal"
                      (click)="showDelConfirm = false;"
                      aria-label="Close">No</button>
              <button class="btn btn-default alertbtn alertbtnyes"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      (click)="deleteRel()">Yes</button>
          </div>
      </div>
  </div>
</div>
<jw-modal id="group-update-success" class="grp-update-success  alert-box">
    <div class="doc-iframe-scs-body ">
        <div class="container">
            <div class="row">
                <div class="alertbox">
                    <div class="close-x float-right" (click)="closeModal('group-update-success');">X</div>
                    <div class="alertpara">
                        <p class="alertparatxt"> Success</p>
                    </div>
                    <div class="alertext" *ngIf="product != 'corporate'">Congratulations!! You have successfully modified Group access for <strong>{{relationshipData.name}}</strong>.</div>                  
                    <div class="alertext" *ngIf="product == 'corporate'">Congratulations!! You have successfully modified Department access for <strong>{{relationshipData.name}}</strong>.</div>                  
                  </div>
            </div>
        </div>
    </div>
</jw-modal>
<!-- grid ends here -->
<div class="modal"
     *ngIf='showConfirm'
     style="display:block;" 
     tabindex="-1">
  <div class="modal-dialog">
      <div class="modal-content">
              <button type="button"
                      class="btn-close closebtn"
                      data-bs-dismiss="modal"(click)="showConfirm = false;" aria-label="Close"></button>
          <div class="modal-body">
              <div class="alertpara">
                  <p class="alertparatxt">Confirm</p>
                </div>
          </div>
          <div class="alertext" style="height:130px;">
            Are you sure you want to send relationship invite to <strong>{{selname}}</strong>.
          </div>
          <div class="alertbutton">
              <button class="btn btn-default  alertbtn alertbtnno"
                      data-bs-dismiss="modal"
                      (click)="showConfirm = false;"
                      aria-label="Close">No</button>
              <button class="btn btn-default alertbtn alertbtnyes"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      (click)="send_invite_temp()">Yes</button>
          </div>
      </div>
  </div>
</div>

<ngx-spinner  size="medium" type="ball-clip-rotate"></ngx-spinner>