<div class="row">
  <form [formGroup]='searchForm' (ngSubmit)='search()'>
  <div class="form-group col-sm-5" style="margin:20px auto">
    <div class="input-group court" id="search">
      <input type="email" 
             class="form-control textbox"
             formControlName='email' (input)="restricttextSpace($event)"
             oninput="this.value = this.value.replace(/[^a-zA-Z0-9._-@]/g,'')"
             placeholder="Search by email"/>

      <button id="btnadd"
              type='submit'
              class="input-group-append appendadd">Search</button>
     </div>
     <div *ngIf="sfSubmitted">
        <div class="invalid-feedback alertEmail"
              *ngIf="email.errors?.required"> Email is required. </div>
        <div class="invalid-feedback alertEmail"
            *ngIf="email.errors?.pattern"> Please enter a valid email address. </div>
     </div>

     <!--Shown Messages-->
    <div class="text-center alertText">
      <p class="textDanger" *ngIf="reqError.show">{{reqError.msg}}</p>
      <div *ngIf="!closeMode"><p class="textGreen" *ngIf="formMode == 'request'">{{msg}}</p></div>
      <p class="textDanger" *ngIf="formMode == 'invite'">{{msg}}</p>
    </div>
     <!--Shown Messages ends here..-->
  </div>

  </form>
</div>
<div class="row">
    <div class="col-12 Individual" [ngClass]="{'hide': !showForm }">
     
      <!--<p class="text-danger" *ngIf="reqError.show">{{reqError.msg}}</p>
      <p class="text-primary" *ngIf="formMode == 'request'">{{msg}}</p>
      <p class="text-danger" *ngIf="formMode == 'invite'">{{msg}}</p>-->

      <form [formGroup]="createRelationform" >
        <div class="row">
          <div class="col-6">
            <div class="form-group nameSet">
              <label for="title">First Name <span class="mandate">*</span>
              </label>
              <input type="text"
                     class="text-size form-control textbox"
                     formControlName="firstName"
                     name="firstName"
                     [ngClass]="{'is-invalid': submitted && f.firstName.errors }">
              <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required">FirstName is Required</div>
              </div>
            </div>
            <div class="form-group nameSet">


              <label for="title">Last Name <span class="mandate">*</span>
              </label>
              <input type="text"
                     class=" form-control textbox"
                     name="lastName"
                     formControlName="lastName"
                     [ngClass]="{'is-invalid':submitted && f.lastName.errors}">
              <div *ngIf="submitted && f.lastName.errors" class=" invalid-feedback">
                <div *ngIf="f.lastName.errors.required">LastName is Required</div>
              </div>

             
            </div>
          </div>
          <div class="col-6">
            <div class="form-group nameSet">
              <label for="title">Confirm Email<span class="mandate">*</span>
              </label>
              <input type="text"
              class="form-control textbox"
              name="confirmEmail"
              formControlName="confirmEmail"
              (keyup)="resetEmailConf()"
              [ngClass]="{'is-invalid':submitted && f.confirmEmail.errors}"/>
              <div *ngIf="submitted && f.confirmEmail.errors" class="invalid-feedback">
                <div *ngIf="f.confirmEmail.errors.required">Confirm Email is Required</div>
                <div *ngIf="f.confirmEmail.errors.mismatch">Confirm Email mismatch.</div>
              </div>
            </div>

            <div class="form-group nameSet">
              <label for="title">Country <span class="mandate">*</span>
              </label>
              <div class="input-group court" id="country">
                <select type="text"
                       class="form-select member-name-text"
                       formControlName="country"
                       name="country"
                       [ngClass]="{'is-invalid':submitted && f.country.errors}">
                       <option value="" disabled selected>Select Country</option>
                       <option *ngFor="let c of countries" value="{{c[0]}}"> {{c[0]}} </option>
                </select>
                <div *ngIf="submitted && f.country.errors" class=" invalid-feedback">
                  <div *ngIf="f.country.errors.required">Country is Required</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group nameSet forGroups">
              <label>Selected Group(s)</label>
              <div *ngIf="!closeMode">
              <div class="input-group court" *ngFor="let sgrp of selectedGroups">
                  <input type="text" class="form-control textbox" id="addedGrp" placeholder="{{sgrp.name}}" disabled>
                  <i class="fa fa-regular fa-circle-xmark calimg calitem input-group-append sgroup"
                     (click)="restore(sgrp)" aria-hidden="true"></i>
              </div></div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group nameSet forGroups">
              <label for="title" *ngIf ="product !='corporate'">Assign the Group(s) <span class="mandate">*</span> </label>
              <label for="title" *ngIf ="product =='corporate'">Assign the Department(s) <span class="mandate">*</span> </label>
              <!--<input type="text"
                     placeholder="Search Groups" 
                     formControlName='grpsearch'
                     (keyup)="onGrpSearch($event)" 
                     class="form-control textbox"/>-->

              <div class="input-group group-custom-align court" id="search" *ngIf ="product !='corporate'">
                    <input type="text" class="form-control textbox" autocomplete="off" placeholder="Search Groups" formControlName='grpsearch' (keyup)="onGrpSearch($event)" />
                    <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
             </div>
             <div class="input-group group-custom-align court" id="search" *ngIf ="product =='corporate'">
              <input type="text" class="form-control textbox" autocomplete="off" placeholder="Search Department" formControlName='grpsearch' (keyup)="onGrpSearch($event)" />
              <img src="assets/img/search.svg" class="calimg calitem input-group-append searchimg">
       </div>
            </div>
            <div class="form-group nameSet">
              <div class="form-group" *ngFor="let grp of groupList | filter:groupFilter">
                  <div class="input-group court" id="search">
                      <div class="multicheck form-control textbox">
                        <div class="callselect">{{grp.name}}</div>
                        <input class="form-check-inpu calimg calitem input-group-append checkboxitem"
                               (click)='selgrp(grp)'
                               type="checkbox" value="" id="flexCheckDefault">
                      </div>
                  </div>
               </div>
            </div>
          </div>
        </div>
        <div class="btnbox">
          <button type="reset"
                  class="btn btn-default btncancel"
                  (click)="onReset()">Cancel</button>
          <button type="submit"
                  class="btn btn-default btnsave"
                  (click)="getConfirmation()"
                  [disabled]="selectedGroups.length == 0">Send Request</button>
        </div>
        
      </form>
    </div>
  </div>
<div class="modal"
     *ngIf='showConfirm'
     style="display:block;" 
     tabindex="-1">
  <div class="modal-dialog">
      <div class="modal-content">
        
              <button type="button"
                      class="btn-close"
                      data-bs-dismiss="modal" 
                      (click)="showConfirm = false;" aria-label="Close">
              </button>

          <div class="modal-body">
              <div class="alertpara">
                  <p class="alertparatxt">Confirm</p>
                </div>
          </div>
          <div class="alertext" style="height:130px;">
            Are you sure you want to send relationship request to <strong>{{selname}}</strong>.
          </div>
          <div class="alertbutton">
              <button class="btn btn-default  alertbtn alertbtnno"
                      data-bs-dismiss="modal"
                      (click)="showConfirm = false;"
                      aria-label="Close">No</button>
              <button class="btn btn-default alertbtn alertbtnyes"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      (click)="sendReq()">Yes</button>
          </div>
      </div>
  </div>
</div>
<div class="modal"
     *ngIf='showSuccess'
     style="display:block;" 
     tabindex="-1">
  <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">

          <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="showSuccess = false;" aria-label="Close">
          </button>

              <div class="alertpara">
                <p class="alertparatxt"> Success</p>
              </div>
              <div class="alertext">Congratulations!! 
                You have successfully sent the relationship request to {{selname}}.
              </div>
              <div class="alertbutton">
                <button class="btn btn-default alertbtn" (click)="successResp('add')">Add Relationship</button>
                <button class="btn btn-default alertbtn" (click)="successResp('view')">View Changes</button>

              

              </div>
          </div>
      </div>
  </div>
</div>