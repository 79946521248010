<!--component html goes here -->
<!-- <div class="switch  cswitch">
  <span class="relSubTab individ" (click)="relationSubTab('individ')">
    <a href="javascript: void(0)" class="optionone compone relation-sub-tabs">Individual</a>
  </span>
  <span class="relSubTab entity" (click)="relationSubTab('entity')">
    <a href="javascript: void(0)" class="optiontwo relation-sub-tabs compotwoo comptwo ">Entity</a>
  </span>
  <span class="relSubTab exPartner" (click)="relationSubTab('exPartner')">
    <a href="javascript: void(0)" class="optiontwo relation-sub-tabs  comptwo">External Partner</a>
  </span>
</div> -->
<div class="row" *ngIf="product != 'corporate'">
  <div class="col-sm-12 compswitch ">
    <div class="switch relationship-tabs cswitch">
      
      <span class="relSubTab individual">
        <a href="javascript: void(0)"
           class="optionone compone relation-sub-tabs tabStyle"
           [class.active]="activeTab == 'individual'"
           routerLink="/relationship/add/individual">Individual</a>
      </span>

      <span class="relSubTab entity" *ngIf="product == 'corporate' || product == 'lauditor'">
        <a href="javascript: void(0)"
           [class.active]="activeTab == 'entity'"
           class="optiontwo relation-sub-tabs compotwoo comptwo cls tabStyle"
           routerLink="/relationship/add/entity">Entity</a>   <!--cls-->
      </span>

      <span class="relSubTab entity" *ngIf="product == 'content'">
        <a href="javascript: void(0)"
           [class.active]="activeTab == 'entity'"
           class="optiontwo relation-sub-tabs compotwoo comptwo tabStyle"
           routerLink="/relationship/add/entity">Entity</a>  
      </span>

      <span class="relSubTab corporate" *ngIf="product == 'corporate' || product == 'lauditor'">
        <a href="javascript: void(0)"
           [class.active]="activeTab == 'corporate'"
           class="optiontwo relation-sub-tabs compotwoo comptwo tabStyle"
           routerLink="/relationship/add/corporate">Corporate</a>
      </span>
    </div>

  </div>
</div>

<div class="row" *ngIf="product == 'corporate'">
  <div class="col-sm-12 compswitch ">
    <div class="switch relationship-tabs cswitch">
      <span class="relSubTab corporate">
        <a href="javascript: void(0)"
           class="optionone compone relation-sub-tabs borderPix"
           [class.active]="activeTab == 'corporate'"
           routerLink="/relationship/add/corporate">External Counsels</a>
      </span>
      <!--<span class="relSubTab entity">
        <a href="javascript: void(0)"
           [class.active]="activeTab == 'entity'"
           class="optiontwo relation-sub-tabs compotwoo comptwo"
           routerLink="/relationship/add/entity">External</a>
      </span>-->
    </div>

  </div>
</div>
<router-outlet></router-outlet>


<!--<app-external-partner  [activeTab]="activeTab"></app-external-partner>-->