<!-- OVERVIEW DIALOG -->
<div class="overviewDialog">
    <form [formGroup]="contentForm">
        <div class="closeDialog">
            <i class="fa fa-times xClose" (click)="closeDialog()" aria-hidden="true"></i>
        </div>

        <div class="openDialog">

            <div class="form-group">
                <label for="title" class="lableTitle">Text</label>
                <textarea formControlName="contentData" maxlength="5000" [(ngModel)]="contentData" (keypress)="onKeyPress($event)" (input)="restrictSplCharacteratFirst($event)" placeholder="Enter your content" class="text-size form-control textareaDoc ovtextarea" rows="3" cols="50"></textarea>
                <p class="textCount">{{contentData.length}}/5000</p>
            </div>

            <div class="overviewSave">
                <button type="submit" (click)="save()" class="btn btn-default btnsave savecls">Save</button>
            </div>

        </div>
    </form>
</div>